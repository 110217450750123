import * as H from 'history';
import IBAN from 'iban';
import Papa from 'papaparse';

import {
    TAddress,
    TContract,
    TCustomer,
    TEPInvoice,
    TMeterRead,
    TPackage,
    TScheme,
    TThirdParty,
    TTransaction,
    TWordingItem,
} from 'Models';

import {
    EActionMode,
    EBillingMode,
    ECivility,
    ECustomerType,
    EFrequency,
    EGroups,
    EInvoiceRoutingMode,
    EMeterReadFrequency,
    EPaymentMode,
    EPAYTransactionStatus,
    EPieceType,
    ERateOption,
    ETimeframe,
} from './enums';

import uniqBy from 'lodash/uniqBy';

import Config from './config';
import WordingCommon from './wording.json';
import { fromMonth_, toMonth_ } from '../components/CustomDate/CustomDate';
import { SelectOption, TSchemaProps } from 'Types';
import map from 'lodash/map';
import get from 'lodash/get';
import resources from './resources';
import { isNil } from 'lodash';

const PackageWording = WordingCommon.PackagePage;
const CommonWording = WordingCommon.Common;

const WordingMapping = WordingCommon.mapping;

const log = (title: string, message: any) => {
    if (Config.ENV === 'PREPRODUCTION') {
        console.log(title, message);
    }
};

const isValidString = (value?: string): boolean => {
    return value !== undefined && value !== '';
};

const isValidEmail = (value?: string): boolean => {
    if (value === undefined) {
        return false;
    }
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(value).toLowerCase());
};

const isValidPhone = (value?: string): boolean => {
    if (value && value.length === 10) {
        const sub = value.substr(0, 2);
        if (sub === '06' || sub === '07') {
            const regex = /^((06)|(07))[0-9]{8}$/;
            return regex.test(String(value));
        }
    }
    return false;
};

const isValidPassword = (value?: string): boolean => {
    if (value === undefined) {
        return false;
    }
    if (value.length < 6) {
        return false;
    }
    const regex = /[^\w\d]*(([0-9]+.*[A-Za-z]+.*)|[A-Za-z]+.*([0-9]+.*))/;
    return regex.test(value);
};

const isValidIban = (value?: string): boolean => {
    if (value === undefined) {
        return false;
    }
    return IBAN.isValid(value);
};

const pad = (s: number) => {
    return s < 10 ? `0${s}` : s;
};

const isValidName = (
    value: string = '',
    allowSpecialChars: boolean = false,
): boolean => {
    if (value === undefined) {
        return false;
    }
    if (allowSpecialChars) {
        return new RegExp(/^[a-zA-Z\' \-]+/g).test(value);
    }
    return value.indexOf(' ') >= 0;
};

const isValidBirthDate = (value: Date | null | undefined): boolean => {
    if (!value) return false;
    const today = new Date();
    const birthDate = value;
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1;
    }
    if (age >= 72) return false;
    const year = value.getFullYear();
    const isInRange =
        year <= toMonth_.getFullYear() && year >= fromMonth_.getFullYear();
    return isInRange;
};

const isValidBirthDatePro = (value: Date | null | undefined): boolean => {
    if (!value) return false;
    const today = new Date();
    const birthDate = value;
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age = age - 1;
    }
    if (age >= 101) return false;
    const year = value.getFullYear();
    const isInRange =
        year <= toMonth_.getFullYear() && year >= fromMonth_.getFullYear();
    return isInRange;
};

const getFormattedDate = (date?: string | Date) => {
    if (!date) {
        return '';
    }

    const d = new Date(date);
    return d
        ? [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
        : undefined;
};

const getFormattedDateToBackEnd = (
    d: Date,
    reversed: boolean = false,
    separator: string = '-',
): string => {
    let units = [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())];
    if (reversed) units = units.reverse();
    return units.join(separator);
};

const getDateFormatted = (d: Date | undefined): string | undefined => {
    if (d !== undefined) {
        return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join(
            '-',
        );
    }
    return undefined;
};

const getTodayDate = (): string => {
    const d = new Date();
    return [d.getFullYear(), pad(d.getMonth() + 1), pad(d.getDate())].join('-');
};

const isWeekend = (date: Date): boolean => {
    return date.getDay() === 6 || date.getDay() === 0;
};

const displayIban = (iban: string): string =>
    iban.toUpperCase().replace(/[^A-Z0-9]/g, '');

const displayNumberOnly = (value: string): string =>
    value.replace(/[^\d ]/g, '');

const displayPhoneWithoutPrefix = (value: string): string =>
    value.replace(/[^\d ][3]{2}/g, '0');

const getPhoneWithPrefix = (value: string) => value.replace(/^(0)/, '+33');

const findContractByNumber = (nbr: string, arr: TContract[]): TContract => {
    const object =
        arr.find((contract: TContract) => contract.contractNumber === nbr) ||
        arr[0];
    return object;
};

const findCoholder = (contract?: TContract): TThirdParty | undefined => {
    if (contract && contract.thirdParties) {
        return contract.thirdParties.find(
            (e: TThirdParty) => e.role === 'COHOLDER',
        );
    }
    return undefined;
};

const displayPlaceholder = (size: number): string => {
    let res = '';
    if (size > 0) {
        const list = Array.from(Array(size).keys());
        list.forEach(i => {
            res += `${i + 1}`;
        });
    }
    return res;
};

const getYoutubeId = (link: string): string => {
    const s = link.split('v=');
    if (s.length < 1) {
        return '';
    }
    let video_id = s[1];
    const ampersandPosition = video_id.indexOf('&');
    if (ampersandPosition !== -1) {
        video_id = video_id.substring(0, ampersandPosition);
    }
    return video_id;
};

const getTimeframeFromLabel = (label: string): string => {
    switch (label) {
        case 'Base':
            return 'TOTAL_HOUR';
        case 'Heure pleine':
            return 'HIGH';
        case 'Heure creuse':
            return 'LOW';
        default:
            return '';
    }
};

const getLabelByValue = (
    value: string,
    arr: Array<{
        value: string;
        label: string;
    }>,
): string => {
    const objFind = arr.find(
        (obj: { value: string; label: string }) => obj.value === value,
    ) || {
        value: '',
        label: '',
    };
    return objFind.label;
};

const transformPriceLabel = (label: string): string => {
    if (label === PackageWording.price.totalhour.label) {
        return PackageWording.price.totalhour.text;
    }
    if (label === PackageWording.price.high.label) {
        return PackageWording.price.high.text;
    }
    if (label === PackageWording.price.low.label) {
        return PackageWording.price.low.text;
    }
    if (label === PackageWording.price.paymentFrequency.label) {
        return PackageWording.price.paymentFrequency.text;
    }
    return label;
};

const displayFrequency = (frequency: EFrequency): string => {
    const freq = PackageWording.frequencies.find(
        f => f.value === frequency,
    ) || {
        text: '',
    };
    return freq.text;
};

const displayRateOption = (rateOption: ERateOption): string => {
    const f = PackageWording.rateOptions.find(r => r.value === rateOption) || {
        text: '',
    };
    return f.text;
};

const displayTimeframe = (timeframe: ETimeframe): string => {
    return CommonWording.timeframeConsumption[timeframe];
};

const displayDateFromNumber = (d: {
    date: number;
    month: number;
    year: number;
}): string => {
    const { date, month, year } = d;
    const dayLabel = `${date < 10 ? `0${date}` : date}`;
    const monthLabel = `${month + 1 < 10 ? `0${month + 1}` : month + 1}`;
    return `${dayLabel}/${monthLabel}/${year}`;
};

const transformAuthorizedPackages = (packages?: TPackage[][]): number[][] => {
    if (packages === undefined) {
        return [[]];
    }
    const res = packages
        .map(p => {
            if (p && p.length === 2) {
                return p
                    .sort((x: TPackage, y: TPackage) =>
                        x.timeframe < y.timeframe ? -1 : 1,
                    )
                    .map((x: TPackage) => parseInt(x.amount, 0));
            }
            return [0, 0];
        })
        .sort((x, y) => (x[0] < y[0] ? -1 : 1));
    return res;
};

const transformAuthorizedPackagesToPackages = (
    packages?: TPackage[][],
): TPackage[][] => {
    if (packages === undefined) {
        return [[]];
    }
    const res = packages
        .map(p => {
            if (p && p.length === 2) {
                return p
                    .sort((x: TPackage, y: TPackage) =>
                        x.timeframe < y.timeframe ? -1 : 1,
                    )
                    .map((x: TPackage) => x);
            }
            return [p[0], p[0]];
        })
        .sort((x, y) =>
            parseInt(x[0].amount, 0) < parseInt(y[0].amount, 0) ? -1 : 1,
        );
    return res;
};

const findPackagesByRange = (
    range: number[],
    packages?: TPackage[][],
): TPackage[] => {
    if (packages === undefined) {
        return [];
    }
    const r = transformAuthorizedPackagesToPackages(packages);
    return (
        r.find(
            (ps: TPackage[]) =>
                Number(ps[0].amount) === range[0] &&
                Number(ps[1].amount) === range[1],
        ) || []
    );
};

const findPackageById = (
    packageID: string,
    packages?: TPackage[],
    recommended?: boolean,
): TPackage | undefined => {
    if (packages === undefined) {
        return undefined;
    }
    const pkg = packages.find(p => p.id === packageID);
    if (pkg === undefined && recommended) {
        return packages.find(p => p.recommended);
    }

    return pkg;
};

const getLabelCivility = (civility: ECivility): string => {
    const value = civility as string;
    const res = WordingCommon.EnumItem.civilities.find(
        (c: TWordingItem) => c.value === value,
    ) || { label: '' };
    return res.label;
};

const getLabelInvoiceRouting = (routingMode: EInvoiceRoutingMode): string => {
    const value = routingMode as string;
    const res = WordingCommon.BillPage.routingMode.values.find(
        (c: TWordingItem) => c.value === value,
    ) || { label: '' };
    return res.label;
};

const getLabelPaymentMode = (paymentMode: EPaymentMode): string => {
    const value = paymentMode as string;
    const res = WordingCommon.EnumItem.paymentModes.find(
        (c: TWordingItem) => c.value === value,
    ) || { label: '' };
    return res.label;
};

const comparePieces = (a: TEPInvoice, b: TEPInvoice): number => {
    const x = new Date(a.invoicing_period_end_date).getTime();
    const y = new Date(b.invoicing_period_end_date).getTime();
    if (x < y) {
        return -1;
    }
    if (x > y) return 1;
    return 0;
};

const findNextAndLastPieces = (
    pieces?: TEPInvoice[],
): { next: TEPInvoice | undefined; last: TEPInvoice | undefined } => {
    let next: TEPInvoice | undefined;
    let last: TEPInvoice | undefined;
    if (pieces === undefined) {
        return {
            next,
            last,
        };
    }
    const sortedPieces = pieces.sort(comparePieces);
    const currentDate = new Date().getTime();
    sortedPieces.forEach((piece: TEPInvoice) => {
        const piecesDate = new Date(piece.invoicing_period_end_date).getTime();
        if (piecesDate > currentDate && next === undefined) {
            next = piece;
        }
        if (piecesDate < currentDate) {
            last = piece;
        }
    });

    return {
        next,
        last,
    };
};

const findLastValidPiece = (
    pieces?: TEPInvoice[],
): { lastValid: TEPInvoice | undefined } => {
    let lastValid: TEPInvoice | undefined;

    if (pieces === undefined) {
        return {
            lastValid,
        };
    }
    const sortedPieces = pieces;
    const currentDate = new Date().getTime();
    sortedPieces.forEach((piece: TEPInvoice) => {
        const dd = new Date(
            piece.invoicing_period_end_date,
        ).toLocaleDateString();
        const piecesDate = new Date(dd).getTime();
        if (
            piecesDate < currentDate &&
            // à remplacer par ('reconciled' ||  'Reconciled')
            piece.status === ('reconciled' || 'Reconciled')
            // && piece.internal_invoice_nature_code === 'instalment'
        ) {
            lastValid = piece;
        }
        // if (
        //    piecesDate < currentDate &&
        //    piece.status === ('reconciled' || 'Reconciled')
        //    && piece.internal_invoice_nature_code === 'settlement'
        // ) {
        //    tmp = piece;
        // }
    });

    return {
        lastValid,
    };
};

const validTransactionStatus = (status: EPAYTransactionStatus) => {
    if (
        status === EPAYTransactionStatus.SUCCESS ||
        status === EPAYTransactionStatus.INITIALIZED ||
        status === EPAYTransactionStatus.TRANSMITTED ||
        status === EPAYTransactionStatus.TRANSACTION_FAILURE ||
        status === EPAYTransactionStatus.DETECTED_ANOMALY ||
        status === EPAYTransactionStatus.DISPUTED
    ) {
        return true;
    }
    return false;
};

const findLastValidTransaction = (
    transactions?: TTransaction[],
): { lastValidTransaction: TTransaction | undefined } => {
    let lastValidTransaction: TTransaction | undefined;
    if (transactions === undefined) {
        return {
            lastValidTransaction,
        };
    }
    const sortedTrans = transactions.sort(compareTransaction);
    const currentDate = new Date().getTime();
    sortedTrans.forEach((transaction: TTransaction) => {
        const transactionDate = new Date(transaction.dueDate).getTime();
        if (
            transactionDate < currentDate &&
            validTransactionStatus(transaction.status)
        ) {
            lastValidTransaction = transaction;
        }
    });
    return {
        lastValidTransaction,
    };
};

const findBillingModeCode = (cntt: any) => {
    return cntt.billingModeCode as EBillingMode;
};

const concatUrl = (
    customer: TCustomer,
    thirdParties: TThirdParty[] | undefined,
): string => {
    const contact = customer.contact;
    const birthdate = contact.birthdate
        ? `&birthdate=${contact.birthdate}`
        : '';
    const url = `${Config.SUBSCRIPTION_LINK}/?customerType=${customer.type}&civility=${contact.customerFullName.civility}&firstName=${contact.customerFullName.firstName}&lastName=${contact.customerFullName.lastName}&email=${contact.email}&phone=${contact.phone}${birthdate}`;

    if (customer.type === ECustomerType.INDIVIDUAL) {
        const coholder = thirdParties
            ? thirdParties.find(p => p.role === 'COHOLDER')
            : undefined;
        if (
            coholder &&
            coholder.civility &&
            coholder.firstName &&
            coholder.lastName
        ) {
            return `${url}&coholderCivility=${coholder.civility}&coholderFirstName=${coholder.firstName}&coholderLastName=${coholder.lastName}`;
        }
        return url;
    }
    return `${url}&companyName=${contact.companyName}&companyType=${contact.companyType}&identificationNumber=${contact.identificationNumber}&activityCode=${contact.activityCode}`;
};

const filteredArr = (arr: any[], type: string): any[] =>
    arr.reduce((acc, current) => {
        const x = acc.find((item: any) => item[type] === current[type]);
        if (!x) {
            return acc.concat([current]);
        }
        return acc;
    }, []);

const compareTransaction = (a: TTransaction, b: TTransaction): number => {
    const x = new Date(a.dueDate).getTime();
    const y = new Date(b.dueDate).getTime();
    if (x < y) {
        return -1;
    }
    if (x > y) return 1;
    return 0;
};

const getLatestTransaction = (
    trans?: TTransaction[],
): TTransaction | undefined => {
    if (trans && trans.length > 0) {
        const sortedTrans = trans.sort(compareTransaction);
        return sortedTrans[sortedTrans.length - 1];
    }
    return undefined;
};

type EnergyConsumption = {
    consumptions: number[];
    highs: number[];
    lows: number[];
    totalhours: number[];
    packages: number[];
    labels: string[];
};

const sortMeters = (meters: TMeterRead[]) =>
    meters.sort((a: TMeterRead, b: TMeterRead) => {
        const x = new Date(a.endDate).getTime();
        const y = new Date(b.endDate).getTime();
        if (x < y) {
            return 1;
        }
        if (x > y) return -1;
        return 0;
    });

const getEnergyConsumption = (
    meters: TMeterRead[],
    ps?: TPackage[],
): EnergyConsumption => {
    const consumptions: number[] = Array(12).fill(0);
    const highs: number[] = Array(12).fill(0);
    const lows: number[] = Array(12).fill(0);
    const totalhours: number[] = Array(12).fill(0);
    let packages: number[] = [];

    if (ps) {
        if (ps.length === 1) {
            packages = Array(12).fill(ps[0].quantityMonthly);
        } else {
            const summer = ps.find(p => p.timeframe === ETimeframe.SUMMER);
            const winter = ps.find(p => p.timeframe === ETimeframe.WINTER);
            const sp = summer ? summer.quantityMonthly : 0;
            const wp = winter ? winter.quantityMonthly : 0;
            packages = [wp, wp, wp, sp, sp, sp, sp, sp, sp, sp, wp, wp];
        }
    }

    const labels: string[] = [];

    let lastYear = new Date();
    if (meters.length > 0) {
        lastYear = new Date(meters[0].endDate);
    }

    lastYear.setFullYear(lastYear.getFullYear() - 1);
    const lastYearDate = lastYear.getTime();
    // Use for loop instead of forEach for better performance
    for (let i = 0; i < meters.length; i += 1) {
        const m = meters[i];

        const start = new Date(m.startDate);

        if (lastYearDate < start.getTime()) {
            const end = new Date(m.endDate);
            const startMonth = start.getMonth();
            const endMonth = end.getMonth();

            const high = (m.meterRead &&
                m.meterRead.find(x => x.timeframe === ETimeframe.HIGH)) || {
                consumption: 0,
            };
            const low = (m.meterRead &&
                m.meterRead.find(x => x.timeframe === ETimeframe.LOW)) || {
                consumption: 0,
            };
            const totalhour = (m.meterRead &&
                m.meterRead.find(
                    x => x.timeframe === ETimeframe.TOTAL_HOUR,
                )) || { consumption: 0 };

            if (m.readingFrequency === EMeterReadFrequency.MONTHLY) {
                consumptions[endMonth] += parseFloat(m.quantity);
                labels.push(m.endDate);
                highs[endMonth] += high.consumption;
                lows[endMonth] += low.consumption;
                totalhours[endMonth] += totalhour.consumption;
            } else if (startMonth === endMonth) {
                consumptions[startMonth] += parseFloat(m.quantity);
                labels.push(m.endDate);
                highs[startMonth] += high.consumption;
                lows[startMonth] += low.consumption;
                totalhours[startMonth] += totalhour.consumption;
            } else {
                let limit = endMonth - startMonth;
                labels.push(m.endDate);
                if (limit < 0) {
                    limit += 12;
                }
                for (let j = 0; j <= limit; j += 1) {
                    const startYear =
                        start.getFullYear() + (startMonth + j > 11 ? 1 : 0);

                    const sj = (startMonth + j) % 12;

                    const s = j === 0 ? start : new Date(startYear, sj, 1);

                    const controlYearE =
                        s.getMonth() === 11 ? startYear + 1 : startYear;

                    const e =
                        j === limit
                            ? end
                            : new Date(controlYearE, (sj + 1) % 12, 1);

                    const fraction = (+e - +s) / (+end - +start);

                    consumptions[sj] += parseFloat(m.quantity) * fraction;

                    highs[sj] += high.consumption * fraction;
                    lows[sj] += low.consumption * fraction;
                    totalhours[sj] += totalhour.consumption * fraction;
                }
            }
        }
    }
    const labelsDateObjects = labels.map(label => new Date(label));
    labelsDateObjects.sort((a, b) => a.getTime() - b.getTime());

    const labelsTimestamps = labelsDateObjects.map(date => date.getTime());

    const minTimestamp = Math.min(...labelsTimestamps);

    const oldestDate = new Date(minTimestamp);
    while (labelsDateObjects.length < 12) {
        const newDate = new Date(oldestDate);
        newDate.setMonth(newDate.getMonth() - 1);
        labelsDateObjects.unshift(newDate);
        oldestDate.setTime(newDate.getTime());
    }

    labelsDateObjects.sort((a, b) => a.getTime() - b.getTime());
    let sortFinalLabels = labelsDateObjects.map(date =>
        date.toLocaleDateString('fr-FR', { month: '2-digit', year: 'numeric' }),
    );
    sortFinalLabels = [...new Set(sortFinalLabels)];
    sortFinalLabels = sortFinalLabels.slice(-12);
    if (meters.length > 0) {
        const endMonth = new Date(meters[0].endDate).getMonth();
        const shift = (endMonth + 1) % 12;
        const consumptionsArray = shiftArray(
            consumptions,
            0,
            shift,
        ) as number[];
        const highsArray = shiftArray(highs, 0, shift) as number[];
        const lowsArray = shiftArray(lows, 0, shift) as number[];
        const totalhoursArray = shiftArray(totalhours, 0, shift) as number[];
        const labelsArray = sortFinalLabels;
        const packageArray = shiftArray(packages, 0, shift) as number[];

        return {
            packages: packageArray,
            consumptions: consumptionsArray,
            highs: highsArray,
            lows: lowsArray,
            totalhours: totalhoursArray,
            labels: labelsArray,
        };
    }

    return { packages, consumptions, highs, lows, totalhours, labels };
};

const getEnergyPercentage = (
    consumptions: number[],
    packages: number[],
    month: number,
): number => {
    if (consumptions.length !== 12 || packages.length !== 12) {
        return 0;
    }
    const m = month === 0 ? 11 : month - 1;
    return Number(((consumptions[m] * 100) / packages[m]).toFixed(2));
};

type StringOrNumber = number | string;

const shiftArray = (
    arr: StringOrNumber[],
    direction: number,
    n: number,
): StringOrNumber[] => {
    const times = n > arr.length ? n % arr.length : n;
    return arr.concat(
        arr.splice(0, direction > 0 ? arr.length - times : times),
    );
};

type DownloadData = {
    row: number;
    date: string;
    context: string;
    startDate: string;
    endDate: string;
    consumption: string;
};

const getConsumptionDownloadData = (meters: TMeterRead[]): DownloadData[] => {
    const data: DownloadData[] = [];

    for (let i = 0; i < meters.length; i += 1) {
        const m = meters[i];

        data.push({
            row: i,
            date: getFormattedDate(m.meterReadDate) || '',
            context: m.consumptionContext,
            startDate: getFormattedDate(m.startDate) || '',
            endDate: getFormattedDate(m.endDate) || '',
            consumption: m.quantity,
        });
    }

    return data;
};

const compareMeterRead = (a: TMeterRead, b: TMeterRead): number => {
    const x = new Date(a.endDate).getTime();
    const y = new Date(b.endDate).getTime();
    if (x < y) {
        return 1;
    }
    if (x > y) return -1;
    return 0;
};

const sortedConsumptionList = (meters?: TMeterRead[]): TMeterRead[] => {
    if (meters) {
        const noDuplicateMeters = removeDuplicateConsumptionList(meters);
        return noDuplicateMeters.sort(compareMeterRead);
    }
    return [];
};

const removeDuplicateConsumptionList = (
    meters?: TMeterRead[],
): TMeterRead[] => {
    if (meters) {
        const seen = new Set();
        return meters.filter(e => {
            const obj = JSON.stringify({
                startDate: e.startDate,
                endDate: e.endDate,
                quantity: e.quantity,
            });
            const duplicate = seen.has(obj);
            seen.add(obj);
            return !duplicate;
        });
    }
    return [];
};

const compare = (a: any, b: any, property: string) => {
    if (a[property] < b[property]) return -1;
    if (a[property] > b[property]) return 1;
    return 0;
};

const displayCustomerNumber = (nbr: string) => {
    const regex = /\bADMIN:/;
    return regex.test(nbr) ? nbr.replace('ADMIN:', '') : nbr;
};

const buildClassName = (nodes: string[], separator: string = '__') => {
    return nodes.join(separator);
};

const capitalizeFirstLetter = (str: string = ''): string => {
    const arr = str.split('');
    if (!!arr[0]) arr[0] = arr[0].toUpperCase();
    return arr.join('');
};

const formatNameString = (
    str: string = '',
    setInitialCap?: boolean,
): string => {
    let fmt = str.replace(/[^\a-zA-Z -- ç é è]/g, '');
    if (!!setInitialCap) fmt = capitalizeFirstLetter(fmt);
    return fmt;
};

function formatFirstNameString(value: string = ''): string {
    return formatNameString(value, true);
}

function formatLastNameString(value: string = ''): string {
    return formatNameString(value, true);
}

function formatGroupNameString(value: string = ''): string {
    return formatNameString(value, true);
}

function formatSIRETString(value: string = ''): string {
    return value.replace(/[^\d]/g, '').slice(0, 14);
}

function formatPhoneNumberString(value: string = ''): string {
    return value.replace(/[^\d]/g, '').slice(0, 10);
}

function formatGroupString(value: string = ''): string {
    return value.replace(/\s/, '').slice(0, 10);
}

function formatAddressString(value: string = ''): string {
    return value.replace(/[^a-z^A-Z^0-9^.^\-^' ]/g, '');
}

function formatPostalCodeString(value: string = ''): string {
    return value.replace(/[^\d]/g, '').slice(0, 5);
}

function formatSiteString(value: string = '') {
    let fmt = value.replace(/[^\d]/g, '').slice(0, 14);
    if (fmt.length === 14) {
        const chunks: string[] = [];
        let i = fmt.length;
        while (i > 0) {
            const startIndex = i - 3 >= 0 ? i - 3 : 0;
            const nextChunk = fmt.substring(startIndex, i);
            chunks.push(nextChunk);
            i = startIndex;
        }
        fmt = chunks.reverse().join(' ');
    }
    return fmt;
}

function formatAlphanumericString(value: string, upper: boolean = false) {
    let str = value.replace(/[^a-z0-9]/gi, '');
    if (upper) str = str.toUpperCase();
    return str;
}

const httpSuccessRegexp = new RegExp(/^2[\d]{2}$/g);

function isHttpSuccess(code: any) {
    return httpSuccessRegexp.test(`${code}`);
}

function parseQueryParams(
    location: H.Location | Location,
    host: string,
    protocol: string,
): Map<string, string | number> {
    const params = new Map<string, string | number>();
    try {
        const path = location.pathname;
        const search = location.search;
        const url = new URL(`${protocol}//${host}${path}${search}`);
        const entries = url.searchParams;
        entries.forEach((value, key) => {
            let typedValue: string | number = value;
            const numberValue = Number(value);
            if (!!numberValue) typedValue = numberValue;

            if (params.has(key)) {
                params.set(
                    key,
                    `${params.get(key) as string}|${typedValue as string}`,
                );
            } else params.set(key, typedValue);
        });
    } catch (err) {
        console.error(err);
        params.clear();
        return params;
    }
    return params;
}

const formatCustomerContract = (customersContract: any[]): any[] => {
    let arr: any[] = [];
    arr = [
        ...arr,
        ...customersContract.map(value => {
            return { ...value };
        }),
    ];
    return arr;
};

const isEmpty = (arr: any[]) => arr && arr.length !== 0;

function getHost(
    params: Map<string, string> = new Map(),
    extendedPath: string[] = [],
): string {
    const protocol = location.protocol;
    const slashes = protocol.concat('//');
    const host = slashes.concat(window.location.host);
    let paramStr: string = '';
    if (params.size > 0) {
        paramStr = '?';
        params.forEach((value: string, key: string) => {
            paramStr += `${key}=${value}`;
        });
    }
    const url = `${host}/${extendedPath.join('/')}${paramStr}`;
    return url;
}

const isEmptySelectOption = (
    selection?: string | SelectOption<string>,
): boolean => {
    return selection !== undefined && selection !== '';
};

function getSelectOptionValue(status: SelectOption<string>): string {
    return status !== null ? (Object.values(status)[2] as string) : '';
}

async function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

type ArrayOrMap<K, V> = V[] | Map<K, V>;
async function forEach<K, V>(
    collection: ArrayOrMap<K, V>,
    fn: (value: V, index: K | number, collection: ArrayOrMap<K, V>) => void,
) {
    if (collection instanceof Array) {
        const length: number = collection.length;
        for (let i = 0; i < length; i = i + 1) {
            await fn(collection[i], i, collection);
        }
    } else if (collection instanceof Map) {
        const entries = collection.entries();
        for (const entry of entries) {
            await fn(entry[1], entry[0], collection);
        }
    }
}

const isLimitedAuthorization = ({ subGroupMode, groupMode }: TSchemaProps) => {
    return groupMode === EGroups.LIMITED && subGroupMode === EGroups.LIMITED;
};

const isFullAuthorization = ({ subGroupMode, groupMode }: TSchemaProps) => {
    return groupMode === EGroups.FULL && subGroupMode === EGroups.FULL;
};

const isMixedAuthorization = ({ subGroupMode, groupMode }: TSchemaProps) => {
    return groupMode === EGroups.LIMITED && subGroupMode === EGroups.FULL;
};

const isHaveActionsPermission = ({ actionMode }: TSchemaProps) => {
    return actionMode === EActionMode.FULL;
};

const getInitialGroup = (group: string) => group.split('_')[0];

const getAllInitialGroups = (groups: string[]) =>
    uniqBy(
        map(groups, (group: string, id: number) => {
            const inGr = getInitialGroup(group);
            return {
                id,
                label: inGr,
                value: inGr,
            };
        }),
        'value',
    );

const isHaveActionsPermissionBySchema = (
    menuLabel: string,
    subMenuLabel: string,
    authSchema: TScheme[],
) => {
    const currMenu = authSchema.find(m => m.label === menuLabel);

    if (currMenu) {
        const currSubMenu = currMenu.subMenu.find(
            sm => sm.label === subMenuLabel,
        );

        return currSubMenu
            ? currSubMenu.actionMode === EActionMode.FULL
            : false;
    }

    return false;
};

const isLimitedAuthorizationBySchema = (
    menuLabel: string,
    subMenuLabel: string,
    authSchema: TScheme[],
) => {
    const currMenu = authSchema.find(m => m.label === menuLabel);

    if (currMenu) {
        const currSubMenu = currMenu.subMenu.find(
            sm => sm.label === subMenuLabel,
        );

        return currSubMenu
            ? currSubMenu.groupMode === EGroups.LIMITED &&
                  currSubMenu.subGroupMode === EGroups.LIMITED
            : false;
    }

    return false;
};

const isUserLimitedBySchema = (
    menuLabel: string,
    subMenuLabel: string,
    authSchema: TScheme[],
) => {
    const currMenu = authSchema.find(m => m.label === menuLabel);

    if (currMenu) {
        const currSubMenu = currMenu.subMenu.find(
            sm => sm.label === subMenuLabel,
        );

        if (currSubMenu) {
            return currSubMenu.userLimited
                ? currSubMenu.userLimited
                : undefined;
        }
    }

    return undefined;
};

const isOdd = (num: number): boolean => (num % 2 ? false : true);

type MappingTypes =
    | 'energyTypes'
    | 'contractStatus'
    | 'orderStatus'
    | 'firstPaymentCBStatus'
    | 'firstPaymentSlimCollectStatus'
    | 'paymentSDD';
const getLabelByType = (value: string, type: MappingTypes) => {
    return getLabelByValue(value, WordingMapping[type]);
};

type TUserStatus = 'ENABLED' | 'DISABLED';
const getImgSrcByUserStatus = (status: TUserStatus) => {
    switch (status) {
        case 'ENABLED':
            return resources['ic-check-validate'];

        case 'DISABLED':
            return resources['ic-cross'];

        default:
            return resources['ic-cross'];
    }
};

const isHtml = (str: string) =>
    str.includes('<a') || str.includes('</a>') || str.includes('</p>');

const compareAddress = (a1: TAddress, a2: TAddress) => {
    let addr1 = '';
    let addr2 = '';
    if (a1.number) {
        addr1 = `${a1.number} ${a1.street}-${a1.townName}-${a1.postalCode}`;
    } else {
        addr1 = `${a1.street}-${a1.townName}-${a1.postalCode}`;
    }
    if (a2.number) {
        addr2 = `${a2.number} ${a2.street}-${a2.townName}-${a2.postalCode}`;
    } else {
        addr2 = `${a2.street}-${a2.townName}-${a2.postalCode}`;
    }
    return JSON.stringify(addr1) === JSON.stringify(addr2);
};

type Line = [string, string];

const additionalRatesDuplicateValue = (arr: Line[]): Line[] => {
    let fined: any[] = [];
    const init: any[] = [];
    arr.forEach(element => {
        if (element[0] === 'Frais lié à la fréquence de prépaiement') {
            if (fined.length === 0) {
                fined = element;
            } else {
                const prevAmount = fined[1].split(' ');
                const nextAmount = element[1].split(' ');

                const result =
                    parseFloat(prevAmount[0]) + parseFloat(nextAmount[0]);

                fined = [fined[0], `${result.toFixed(2)} €`];
            }
        } else init.push(element);
    });

    return [...init, [...fined]];
};

const buildPackagesForSlider = (packages: []) => {
    const init = [[]];

    let nbrElmInSection = 0;
    let countSectionNbr = 0;

    let recommendedInfo: any[] = [];

    packages.forEach(pkg => {
        if (nbrElmInSection < 4) {
            init[countSectionNbr].push(pkg);

            if ((pkg as any).recommended) {
                recommendedInfo = [countSectionNbr, nbrElmInSection];
            }
            nbrElmInSection += 1;
        } else {
            init.push([]);

            countSectionNbr += 1;
            nbrElmInSection = 1;

            init[countSectionNbr].push(pkg);

            if ((pkg as any).recommended) {
                recommendedInfo = [countSectionNbr, nbrElmInSection];
            }
        }
    });

    let finalBuild: any[] = [];
    let first: any[] = [];
    let isRecommendedPush = false;

    init.forEach((arr, idx) => {
        if (idx === recommendedInfo[0]) {
            isRecommendedPush = true;
        }

        if (isRecommendedPush) finalBuild = [...finalBuild, ...arr];
        else first = [...first, ...arr];
    });

    return [...finalBuild, ...first];
};

const array_move = (arr: any[], old_index: number, new_index: number) => {
    if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        // tslint:disable-next-line:no-increment-decrement
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
};

const canSelectAllPackage = () => {
    const userGroup = localStorage.getItem('userGroup');

    if (userGroup === null) return false;

    return Config.GroupsCanSelectAllPackage.reduce(
        (acc, curr) => acc || userGroup.includes(curr),
        false,
    );
};

const addSelectAllToSelect = (opt: any[]) => [
    {
        id: 'select-all',
        label: 'Tout sélectionner',
        value: 'select-all',
    },
    ...opt,
];

const parseSrcIframe = (dt: string): string => {
    const iframeRegex: RegExp = /<iframe.*?src="(.*?)"/;
    const src: RegExpExecArray | null = iframeRegex.exec(dt || '');
    return src ? src[1] : '';
};

const addBusinessDays = (days: any, date = new Date()) => {
    let nDays = days;
    const now = new Date(date);
    const dayOfTheWeek = now.getDay();
    const deliveryDay = dayOfTheWeek + days;
    let calendarDays = days;
    let deliveryWeeks;
    if (deliveryDay >= 6) {
        nDays -= 6 - dayOfTheWeek;
        calendarDays += 2;
        deliveryWeeks = Math.floor(nDays / 5);
        calendarDays += deliveryWeeks * 2;
    }
    now.setTime(now.getTime() + calendarDays * 24 * 60 * 60 * 1000);
    return now;
};

const addDays = (date: string | Date, days: number) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

/**
 *
 * @param {Date} date
 * @param {'EL' | 'NG'} energy
 * @param {'NOW' | 'LATER'} type
 * @param {boolean} isCyclic
 *
 * *SWITCH_NOW (default dueDate +0)
 * ---------- EL => dueDate + 7 business + 2 calenders days
 * ---------- NG => dueDate + 7 business + 4 calenders days
 *
 * *SWITCH_LATER (default dueDate +14)
 * ---------- EL => dueDate + 7 business + 2 calenders
 * ---------- NG => dueDate + 7 business + 4 calenders
 */
const prepareEffectiveStartDate = (
    date: Date,
    energy: 'EL' | 'NG',
    type: 'NOW' | 'LATER',
    isCyclic: boolean = false,
) => {
    const { EffectiveStartDate } = Config;

    const { businessDays, calendarDays } = EffectiveStartDate[type][energy];
    if (isCyclic || type === 'NOW') {
        return date;
    } else {
        const nextBusinessDate = addBusinessDays(businessDays, date);
        return addDays(nextBusinessDate, calendarDays);
    }
};

const prepareFrequenciesToDisplay = (arr: any[]) => {
    const predicate = (a: any, b: any) => {
        const map = {
            MONTHLY: 0,
            BIMONTHLY: 1,
            QUARTERLY: 2,
            FOURMONTHLY: 3,
        } as any;

        if (map[a.value] < map[b.value]) {
            return -1;
        }

        if (map[a.value] > map[b.value]) {
            return 1;
        }

        return 0;
    };

    return arr.sort(predicate);
};

const addMonth = (date: Date, nmMonth: number) =>
    new Date(date.setMonth(date.getMonth() + nmMonth));

const transformPieceType = (pt: string) => {
    switch (pt) {
        case EPieceType.ADHOC:
            return 'adhoc';

        default:
            return pt;
    }
};
const getAPEFromObject = (obj: any) =>
    (get(
        obj,
        'etablissement.uniteLegale.activitePrincipaleUniteLegale',
        '',
    ) as any).replaceAll('.', '');

const parseUploadAmendments = async (file: File): Promise<any> =>
    new Promise((resolve, reject) =>
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete(results: any) {
                if (results.errors.length === 0) return resolve(results.data);

                console.error('Parsing errors: ', results.errors);
                return reject(results.errors);
            },
        }),
    );

const checkIsDisableSwitchSelect = ({ channel }: { channel: string }) => {
    if (isNil(channel)) return false;

    return Config.ChannelsFixSwitchLater.some(curr => curr.test(channel));
};

export {
    compare,
    checkIsDisableSwitchSelect,
    parseUploadAmendments,
    transformPieceType,
    addMonth,
    getAPEFromObject,
    prepareFrequenciesToDisplay,
    parseSrcIframe,
    prepareEffectiveStartDate,
    addSelectAllToSelect,
    canSelectAllPackage,
    array_move,
    buildPackagesForSlider,
    isOdd,
    isHtml,
    getImgSrcByUserStatus,
    displayCustomerNumber,
    additionalRatesDuplicateValue,
    compareAddress,
    getLabelByType,
    isHaveActionsPermission,
    getAllInitialGroups,
    isMixedAuthorization,
    isHaveActionsPermissionBySchema,
    log,
    isLimitedAuthorizationBySchema,
    findBillingModeCode,
    isFullAuthorization,
    getInitialGroup,
    filteredArr,
    isValidPhone,
    isValidEmail,
    isValidPassword,
    isValidIban,
    isValidName,
    isValidString,
    isValidBirthDate,
    isValidBirthDatePro,
    findCoholder,
    getLabelCivility,
    getLabelInvoiceRouting,
    getLabelPaymentMode,
    getFormattedDate,
    findContractByNumber,
    displayIban,
    isLimitedAuthorization,
    isUserLimitedBySchema,
    displayNumberOnly,
    getTodayDate,
    displayPlaceholder,
    getYoutubeId,
    getTimeframeFromLabel,
    getLabelByValue,
    getFormattedDateToBackEnd,
    transformPriceLabel,
    displayFrequency,
    displayDateFromNumber,
    displayRateOption,
    displayTimeframe,
    transformAuthorizedPackages,
    findPackageById,
    findPackagesByRange,
    findNextAndLastPieces,
    findLastValidPiece,
    findLastValidTransaction,
    displayPhoneWithoutPrefix,
    getPhoneWithPrefix,
    concatUrl,
    isWeekend,
    getLatestTransaction,
    getEnergyConsumption,
    getEnergyPercentage,
    shiftArray,
    getConsumptionDownloadData,
    sortedConsumptionList,
    removeDuplicateConsumptionList,
    buildClassName,
    formatNameString,
    capitalizeFirstLetter,
    formatFirstNameString,
    formatLastNameString,
    formatSIRETString,
    formatPhoneNumberString,
    formatAddressString,
    formatPostalCodeString,
    formatSiteString,
    formatCustomerContract,
    isEmpty,
    getDateFormatted,
    isEmptySelectOption,
    formatAlphanumericString,
    isHttpSuccess,
    parseQueryParams,
    getHost,
    getSelectOptionValue,
    formatGroupString,
    sleep,
    formatGroupNameString,
    forEach,
    addDays,
    sortMeters,
};
