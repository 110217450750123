// ENUMS

export enum EAdditionalRateTypes {
    DISCOUNT = 'DISCOUNT',
    FEE = 'FEE',
    OPTION = 'OPTION',
}

export enum EBillingModes {
    CYCLICAL_BILLING = 'CYCLICAL_BILLING',
    PAYMENT_SCHEDULE = 'PAYMENT_SCHEDULE',
}

export enum EtypePayment {
    SDD = 'SDD',
    OTHER = 'OTHER',
}

export enum ECategories {
    TOTAL_AMOUNT = 'TOTAL_AMOUNT',
    ADDITIONAL_RATE = 'ADDITIONAL_RATE',
    TOTAL_BUDGET = 'TOTAL_BUDGET',
    ECONOMY = 'ECONOMY',
    DISCOUNTED_UNIT_PRICE = 'DISCOUNTED_UNIT_PRICE',
    DISCOUNTED_UNIT_PRICE_TTC = 'DISCOUNTED_UNIT_PRICE_TTC',
}

export enum ECivilities {
    MR = 'MR',
    MS = 'MS',
    MRS = 'MRS',
}

export enum ECompanyTypes {
    EARL = 'EARL',
    EI = 'EI',
    EIRL = 'EIRL',
    EURL = 'EURL',
    GAEC = 'GAEC',
    GEIE = 'GEIE',
    GIE = 'GIE',
    SA = 'SA',
    SARL = 'SARL',
    SAS = 'SAS',
    SASU = 'SASU',
    SC = 'SC',
    SCA = 'SCA',
    SCI = 'SCI',
    SCIC = 'SCIC',
    SCM = 'SCM',
    SCOP = 'SCOP',
    SCP = 'SCP',
    SCS = 'SCS',
    SEL = 'SEL',
    SELAFA = 'SELAFA',
    SELARL = 'SELARL',
    SELAS = 'SELAS',
    SELCA = 'SELCA',
    SEM = 'SEM',
    SEML = 'SEML',
    SEP = 'SEP',
    SICA = 'SICA',
    SNC = 'SNC',
    OTHER = 'OTHER',
}

export enum EContractStatuses {
    ABANDONED = 'ABANDONED',
    SIGNED = 'SIGNED',
    SIGNING_FAILURE = 'SIGNING_FAILURE',
    WAITING = 'WAITING',
}

export enum EContractualCapacityUnits {
    KVA = 'kVA',
}

export enum ECustomerStatuses {
    CUSTOMER = 'CUSTOMER',
    PROSPECT = 'PROSPECT',
}

export enum ECustomerTypes {
    INDIVIDUAL = 'INDIVIDUAL',
    PROFESSIONAL = 'PROFESSIONAL',
}

export enum EDeliveryPointCategories {
    C1 = 'C1',
    C2 = 'C2',
    C3 = 'C3',
    C4 = 'C4',
    C5 = 'C5',
    T1 = 'T1',
    T2 = 'T2',
    T3 = 'T3',
}

export enum EDeliveryPointStates {
    IN_USE = 'IN_USE',
    NOT_IN_USE = 'NOT_IN_USE',
    UNDER_CONSTRUCTION = 'UNDER_CONSTRUCTION',
}

export enum EDeliveryStatuses {
    ACTIVE = 'ACTIVE',
    INACTIVE = 'INACTIVE',
}

export enum EEffectiveStartRanges {
    LATER = 'LATER',
    NOW = 'NOW',
}

export enum EEnergyTypes {
    ELECTRICTY = 'EL',
    GAS = 'NG',
}

export enum EFirstPaymentCBStatuses {
    CANCELED = 'CANCELED',
    FINALIZED = 'FINALIZED',
    IN_FAILURE = 'IN_FAILURE',
    IN_PROGRESS = 'IN_PROGRESS',
    PENDING = 'PENDING',
}

export enum EHeatingTypes {
    COLLECTIVE = 'COLLECTIVE',
    ELECTRICITY = 'ELECTRICITY',
    GAS = 'GAZ',
}

export type TRateOptions = 'HIGH_LOW' | 'TOTAL_HOUR' | 'UNKNOWN';

export enum EGridRates {
    T1 = 'T1',
    T2 = 'T2',
    T3 = 'T3',
    BTINFCUST = 'BTINFCUST',
    BTINFMUDT = 'BTINFMUDT',
    BTINFLU = 'BTINFLU',
    BTINFCU4 = 'BTINFCU4',
    BTINFMU4 = 'BTINFMU4',
}

export enum EHousingTypes {
    APPARTMENT = 'APPARTMENT',
    HOUSE = 'HOUSE',
}

export enum EInvoiceRoutingModes {
    EMAIL = 'EMAIL',
    MAIL = 'MAIL',
    BOTH = 'BOTH',
}

export enum EInstallmentFrequencies {
    MONTHLY = 'MONTHLY',
    BIMONTHLY = 'BIMONTHLY',
    QUARTERLY = 'QUARTERLY',
    FOURMONTHLY = 'FOURMONTHLY',
}

export enum EOrderStatuses {
    CANCELED = 'CANCELED',
    FINALIZED = 'FINALIZED',
    FINALIZED_WITHOUT_DOC = 'FINALIZED_WITHOUT_DOC',
    IN_FAILURE = 'IN_FAILURE',
    IN_PROGRESS = 'IN_PROGRESS',
}

export enum EPaymentModes {
    BANK_TRANSFER = 'BANK_TRANSFER',
    CHECK = 'CHECK',
    DIRECT_DEBIT = 'DIRECT_DEBIT',
}

export enum EProcessTypes {
    MOVE_IN = 'MOVE_IN',
    SWITCH = 'SWITCH',
}

export enum EProductTypes {
    FIXED = 'FIXED',
    VARIABLE = 'VARIABLE',
}

export enum EProfiles {
    RES1 = 'RES1',
    RES11 = 'RES11',
    RES2 = 'RES2',
    P011 = 'P011',
    P012 = 'P012',
    PRO1 = 'PRO1',
    PRO2 = 'PRO2',
    RES3 = 'RES3',
    RES4 = 'RES4',
    RES5 = 'RES5',
    PRO3 = 'PRO3',
    PRO4 = 'PRO4',
    PRO5 = 'PRO5',
}

export enum ERateOptions {
    HIGH_LOW = 'HIGH_LOW',
    TOTAL_HOUR = 'TOTAL_HOUR',
    UNKNOWN = 'UNKNOWN',
}

export enum EReadingFrequencies {
    MONTHLY = 'MONTHLY',
    BIMONTHLY = 'BIMONTHLY',
    BIANNUAL = 'BIANNUAL',
    UNKNOWN = 'UNKNOWN',
}

export enum ERebateCodeTypes {
    CASHBACK = 'MY_WORLD',
    PROMOTION = 'PROMOTION',
    SPONSOR = 'SPONSOR',
}

export enum EResidenceTypes {
    PRINCIPAL = 'PRINCIPAL',
    SECONDARY = 'SECONDARY',
}

export enum EResultSponsor {
    OK = 'OK',
    KO = 'KO',
}

export enum ERoles {
    COHOLDER = 'COHOLDER',
    MY_WORLD = 'MY_WORLD',
    PARTNER = 'PARTNER',
    PROMOTION = 'PROMOTION',
    SPONSOR = 'SPONSOR',
}

export enum ESavingModes {
    SEND_EMAIL_LINK = 'SEND_EMAIL_LINK',
    STATUS_CANCELED = 'STATUS_CANCELED',
    STATUS_SIGNED = 'STATUS_SIGNED',
}

export enum ESettlementFrequencies {
    BIANNUAL = 'BIANNUAL',
    BIMONTHLY = 'BIMONTHLY',
    FOURMONTHLY = 'FOURMONTHLY',
    QUARTERLY = 'QUARTERLY',
}

export enum ESubscriptionTypes {
    ELECTRICITY = 'ELECTRICITY',
    ELECTRICITY_AND_GAS = 'ELECTRICITY_AND_GAS',
    GAS = 'GAS',
}

export enum ETimeframes {
    HIGH = 'HIGH',
    LOW = 'LOW',
    SUMMER = 'SUMMER',
    SUMMER_HIGH = 'SUMMER_HIGH',
    SUMMER_LOW = 'SUMMER_LOW',
    TOTAL_HOUR = 'TOTAL_HOUR',
    UNKNOWN = 'UNKNOWN',
    WINTER = 'WINTER',
    WINTER_HIGH = 'WINTER_HIGH',
    WINTER_LOW = 'WINTER_LOW',
}

export enum ETimeslots {
    AFTERNOON = 'AFTERNOON',
    DAY = 'DAY',
    MORNING = 'MORNING',
    NONE = 'NONE',
}

export enum ETypes {
    FEE = 'FEE',
    OPTION = 'OPTION',
    DISCOUNT = 'DISCOUNT',
}

export enum EUnitAmounts {
    EURO_KWH = '€/KWH',
    EURO_YEAR = '€/YEAR',
    EURO_MONTH = '€/MONTH',
    EURO = '€',
    PERCENT = 'PERCENT',
}

// TYPES

export type TAdditionalRate = {
    code?: string;
    label?: string;
    description?: string;
    type?: EAdditionalRateTypes;
    detail?: TUnitPriceWithLabel;
    active?: boolean;
    ecofriendly?: boolean;
    updated?: boolean;
};

export type TAddress = {
    number: string;
    street: string;
    netArea?: number;
    postalCode?: number;
    townName?: string;
};

export type TAmountWithLabel = {
    label?: string;
    amount?: string;
    unit?: EUnitAmounts;
    category?: ECategories;
    type?: ETypes;
    unitPrices?: TUnitPriceWithLabel[];
};

export type TAppointmentDay = {
    available: boolean;
    date: string; // Date
    express: boolean;
    firstPaymentCB: boolean;
};

export type TAuthorizedPackage = [TSeasonalPackage, TSeasonalPackage];

export type TContract = {
    billingModeCode?: EBillingModes;
    chosenPackages?: Array<TPackage | TSeasonalPackage>;
    chosenProduct?: TProduct;
    contractCategory?: string;
    contractNumber?: string;
    contractStatus?: EContractStatuses;
    deliveryPoint?: TDeliveryPoint;
    effectiveStartDate?: string;
    dueDate?: string;
    effectiveStartRange?: EEffectiveStartRanges;
    energy: EEnergyTypes;
    estimates?: TEstimate[];
    finance?: TFinance;
    firstPaymentCB: boolean;
    installmentFrequency?: EInstallmentFrequencies;
    rateOption?: ERateOptions;
    settlementFrequency?: ESettlementFrequencies;
    sponsorNumber?: string;
    subscriptionDate?: string;
    thirdParties?: Array<{}>;
    debitDay?: number;
};

export type TCustomer = {
    customerStatus: ECustomerStatuses;
    customerNumber: string;
    type: ECustomerTypes;
    contact: TCustomerContact;
    typePayment: EtypePayment;
    finance: TFinance;
};

export type TCustomerContact = {
    civility: ECivilities;
    firstName: string;
    lastName: string;
    birthdate: string;
    address: TAddress;
    birthTown: TBirthTown;
    email: string;
    phone: string;
    companyName?: string;
    companyType?: ECompanyTypes;
    identificationNumber?: string;
    activityCode?: string;
};

export type TDeliveryPoint = {
    state?: EDeliveryPointStates;
    gridRate?: EGridRates;
    deliveryStatus?: EDeliveryStatuses;
    address?: TAddress;
    profile?: EProfiles;
    deliveryPointCategory?: EDeliveryPointCategories;
    readingFrequency?: EReadingFrequencies;
    contractualCapacity?: number;
    contractualCapacityUnit?: EContractualCapacityUnits;
    pointOfDelivery?: string;
};

export type TEstimate = {
    quantity?: number;
    timeframe?: ETimeframes;
    energy?: EEnergyTypes;
};

export type TFinance = {
    paymentMode: EPaymentModes;
    billingContact: TCustomerContact;
    ibanCode: string;
    bicCode: string;
    bankName: string;
    accountOwnerLastName: string;
    accountOwnerFirstName: string;
    rumCode: string;
    invoiceRoutingMode: EInvoiceRoutingModes;
    invoiceEmail: string;
    debitDay: string;
    termCode: string;
};

export type TGetOrderResponse = {
    order: TOrder;
};

export type TGetOrderStatusResponse = {
    firstPaymentCBStatus: EFirstPaymentCBStatuses;
    orderStatus: EOrderStatuses;
};

export type TGetPackageResponse = {
    contracts: TContract[];
    packagesList: TPackage[];
    prepaymentFrequencies: TPrepaymentFrequencies[];
};

export type TGetProductResponse = {
    productsList: TProduct[];
};

export type TOffer = {
    product: TProduct;
    energy: EEnergyTypes;
    estimateYearly: TEstimate;
    details: TAmountWithLabel[];
};

export type TOrder = {
    orderReference?: string;
    externalReference?: string;
    partnerReference?: string;
    orderStatus?: EOrderStatuses;
    firstPaymentCBStatus?: EFirstPaymentCBStatuses;
    customer: TCustomer;
    contracts: TContract[];
    createdBy: string;
};

export type TOrderResponse = {
    order: TOrder;
    channel: string;
};

export type TPackage = {
    energy: EEnergyTypes;
    amount: string;
    currency: string;
    quantityMonthly: number;
    packageQuantity: number;
    recommended?: boolean;
    id: string;
    timeframe: ETimeframes;
    authorizedPackages?: TAuthorizedPackage[];
};

export type TPaymentCBResponse = {
    iframe: string;
    orderExternalReference: string;
    orderNumber: string;
    stripe: {
        amount: string;
        clientSecret: string;
        billName: string;
    };
};

export type TProcess = {
    type: EProcessTypes;
    express: boolean;
    appointmentTimeslot: ETimeslots;
};

export type TProduct = {
    additionalRates?: TAdditionalRate[];
    default?: string;
    description?: string;
    effectiveDate?: string;
    energy?: EEnergyTypes;
    label?: string;
    productCode?: string;
    productType?: EProductTypes;
    rateOption?: ERateOptions;
};

export type TSaveOrderResponse = {
    orderExternalReference: string;
    orderNumber: string;
};

export type TSeasonalPackage = {
    amount: number;
    currency: string;
    energy: EEnergyTypes;
    id: string;
    packageQuantity: number;
    quantityMonthly: number;
    timeframe: ETimeframes.SUMMER | ETimeframes.WINTER;
};

export type TSignOrderResponse = {
    iframe: string;
    orderExternalReference: string;
    orderNumber: string;
};

export type TCasesExtractResponse = {
    data: string;
};

export type TSurvey = {
    energyList?: EEnergyTypes[];
    housingType: EHousingTypes;
    residenceType: EResidenceTypes;
    area: number;
    inhabitantsNumber: number;
    heatingType: EHeatingTypes;
    rateOption: ERateOptions;
    address: TAddress;
};

export type TThirdParty = {
    code?: string;
    role: ERoles;
    civility?: ECivilities;
    firstName?: string;
    lastName?: string;
    effectiveDate?: string;
};

export type TUnitPriceWithLabel = {
    label?: string;
    price?: string;
    unit?: EUnitAmounts;
};

export type TOrderPayload = Partial<{
    fromDate: string | null;
    toDate: string | null;
    email: string | null;
    orderNumber: string | null;
    firstName: string | null;
    lastName: string | null;
    processType: string | null;
    phone: string | null;
    externalReference: string | null;
    channel: string | null | string[];
    createdBy: string | null;
}>;

export type TPrepaymentFrequencies = {
    frequency: EInstallmentFrequencies;
    label: string;
    fee: string;
    currency: string;
};

export type TBirthTown = {
    number: string;
    street: string;
    netArea: string | number;
    postalCode: string | number;
    townName: string;
    country: string;
};
