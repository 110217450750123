import React, { ChangeEvent, Component, Fragment } from 'react';
import _ from 'lodash';
import WordingConstant from '../../../../../utils/wording.json';
import {
    buildClassName as bcn,
    getFormattedDateToBackEnd,
    parseQueryParams,
    addDays,
    prepareEffectiveStartDate,
    addMonth,
    checkIsDisableSwitchSelect,
} from '../../../../../utils/helpers';
import { setState } from '../../../../../utils/reactHelpers';
import {
    AddressSelect,
    CustomDatePicker,
    CustomInput,
    CustomRadio,
    CustomSelect,
    CoordsInputs,
} from '../../../../../components';
import {
    ECivilities,
    EEffectiveStartRanges,
    EEnergyTypes,
    EProcessTypes,
    ESubscriptionTypes,
    EPaymentModes,
    ETimeslots,
    TAddress,
    TAppointmentDay,
} from '../../../../../utils/network/types';
import {
    getAddressStreetLabel,
    toEnergies,
} from '../../../../../utils/network/helpers';
import { RequestError } from '../../../../../utils/network/errors';
import { Modifier } from 'react-day-picker';
import config from '../../../../../utils/config';
import { SelectOption } from 'Types';
import Data from './Data';
import replace from 'lodash/replace';

type DateContract = {
    date: number;
    firstPaymentCB: boolean;
    isCyclicalProduct: boolean;
    month: number;
    timeslot: ETimeslots | null;
    timeslots: ETimeslots[] | null;
    year: number;
};

type Coords = {
    civility: ECivilities | null;
    firstName: string;
    lastName: string;
};

interface IProps {
    cyclicalProducts: EEnergyTypes[];
    handleDebitDay: (
        value: number
    ) => void;
    channel: string;
    getCalendarAppointment: (
        energyType: EEnergyTypes,
        isIndividual: boolean,
        onInit?: boolean,
    ) => Promise<TAppointmentDay[] | RequestError>;
    initialParams?: Data;
    isIndividual: boolean;
    onValidationStatusChange: (
        validated: boolean,
        situation: {
            effectiveStartRange?: EEffectiveStartRanges;
            electricityEffectiveStartDate?: Date;
            gasEffectiveStartDate?: Date;
            electricityDueDate?: Date;
            gasDueDate?: Date;
            electricityExpress?: boolean;
            gasExpress?: boolean;
            electricityTimeslot?: ETimeslots;
            gasTimeslot?: ETimeslots;
            electricityFirstPaymentCB?: boolean;
            gasFirstPaymentCB?: boolean;
            billingAddress?: TAddress;
            coords?: Coords;
            iban?: string;
            processType?: EProcessTypes;
            thirdParty?: {
                civility: ECivilities;
                firstName: string;
                lastName: string;
            };
        },
    ) => void;
    searchAppointmentTimeslot: (
        date: Date,
        energy: EEnergyTypes,
    ) => Promise<ETimeslots[] | RequestError>;
    siteOptionSelection: ESubscriptionTypes | null;
}

interface IState {
    addressValue: string;
    debitDay?: string;
    addressCoords?: Coords;
    changingSupplierStartTime: SelectOption<EEffectiveStartRanges> | null;
    civilitySelection: ECivilities | null;
    currentProcessTypeSelection: EProcessTypes;
    electricityAppointments: TAppointmentDay[];
    electricityExpress: boolean;
    electricityFirstPaymentCB: boolean;
    electricityTimeslots: ETimeslots[] | null;
    electricityTimeslotSelection: ETimeslots | null;
    firstNameValue: string;
    gasAppointments: TAppointmentDay[];
    gasExpress: boolean;
    gasFirstPaymentCB: boolean;
    gasTimeslots: ETimeslots[] | null;
    gasTimeslotSelection: ETimeslots | null;
    IBANValue: string;
    identicalAddressToggleState: boolean;
    isJointContract: boolean;
    movingInElectricityDatePick: Date | null;
    movingInGasDatePick: Date | null;
    paymentModeSelection: SelectOption<EPaymentModes> | null;
    postalCodeSelection: TAddress | null;
    lastNameValue: string;
}

function filterAppointments(
    appointments: TAppointmentDay[] = [],
    type: 'express' | 'available',
    condition: boolean,
): Modifier[] {
    if (appointments.length <= 0) return [];
    const modifiers = [];
    appointments
        .filter(element => {
            return type === 'express'
                ? element[type] === condition && element.available
                : element[type] === condition;
        })
        .forEach(val => {
            return modifiers.push(new Date(val.date));
        });
    if (type === 'available' && !condition) {
        const lastDay =
            appointments && new Date(appointments.slice(-1)[0].date);
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        modifiers.push({
            before: tomorrow,
            after: lastDay,
        });
    }
    return modifiers;
}

function matchDate(
    apps: TAppointmentDay[],
    date: Date,
): TAppointmentDay | null {
    let match: TAppointmentDay | null = null;
    _.forEach(apps, app => {
        try {
            const appDate = new Date(app.date);
            const isSameDay =
                appDate.getFullYear() === date.getFullYear() &&
                appDate.getMonth() === date.getMonth() &&
                appDate.getDate() === date.getDate();
            if (isSameDay) {
                match = app;
                return false;
            }
        } catch (err) {
            console.error(err);
        }
    });
    return match;
}

type MovingInParams = Pick<
    IState,
    | 'electricityAppointments'
    | 'electricityExpress'
    | 'electricityFirstPaymentCB'
    | 'electricityTimeslots'
    | 'electricityTimeslotSelection'
    | 'gasAppointments'
    | 'gasExpress'
    | 'gasFirstPaymentCB'
    | 'gasTimeslots'
    | 'gasTimeslotSelection'
    | 'movingInElectricityDatePick'
    | 'movingInGasDatePick'
>;

type SwitchParams = Pick<
    IState,
    'movingInElectricityDatePick' | 'movingInGasDatePick'
>;

function toDateContract(
    EL: {
        date: Date | null;
        firstPaymentCB: boolean;
        isCyclicalProduct: boolean;
        startTime: EEffectiveStartRanges | null;
        timeslots: ETimeslots[] | null;
        timeslotSelection: ETimeslots | null;
    },
    NG: {
        date: Date | null;
        firstPaymentCB: boolean;
        isCyclicalProduct: boolean;
        startTime: EEffectiveStartRanges | null;
        timeslots: ETimeslots[] | null;
        timeslotSelection: ETimeslots | null;
    },
): any {
    const bundle: any = {};
    if (!!EL.date) {
        bundle.EL = {
            date: EL.date.getDate(),
            firstPaymentCB: EL.firstPaymentCB,
            isCyclicalProduct: EL.isCyclicalProduct,
            month: EL.date.getMonth(),
            switch: EL.startTime,
            timeslots: EL.timeslots,
            timeslot: EL.timeslotSelection,
            year: EL.date.getFullYear(),
        } as DateContract;
    }
    if (!!NG.date) {
        bundle.NG = {
            date: NG.date.getDate(),
            firstPaymentCB: NG.firstPaymentCB,
            isCyclicalProduct: NG.isCyclicalProduct,
            month: NG.date.getMonth(),
            switch: NG.startTime,
            timeslots: NG.timeslots,
            timeslot: NG.timeslotSelection,
            year: NG.date.getFullYear(),
        } as DateContract;
    }
    return bundle;
}

const Wording = WordingConstant.AdminSubscriptionContainer.Situation;
const WordingDebitDay = WordingConstant.ProfilePage;
const baseClassName = 'situation-section';
const radioToggleClassName = 'radio-buttons';
const detailsClassName = 'details';
const billingInformationsClassName = 'billing';
const paymentModeOptions: Array<SelectOption<EPaymentModes>> = [
    {
        id: '0',
        label: Wording.paymentModes.directDebit,
        value: EPaymentModes.DIRECT_DEBIT,
    },

    {
        id: '1',
        label: Wording.paymentModes.paymentCB,
        value: EPaymentModes.BANK_TRANSFER,
    },
];

const preparePaymentModOptions = ({
    firstPaymentCB,
    isCyclicalProduct,
    cyclicalProducts,
    energyTypes,
}: {
    firstPaymentCB: {
        elec: boolean;
        gas: boolean;
    };
    isCyclicalProduct: {
        elec: boolean;
        gas: boolean;
    };
    cyclicalProducts: EEnergyTypes[];
    energyTypes: EEnergyTypes[];
}) => {
    if (energyTypes.length > 1) {
        return [
            {
                ...paymentModeOptions[
                    (firstPaymentCB.elec || firstPaymentCB.gas) &&
                    cyclicalProducts.length !== 2
                        ? 1
                        : 0
                ],
            },
        ];
    }

    return [
        {
            ...paymentModeOptions[
                (firstPaymentCB.elec || firstPaymentCB.gas) &&
                !isCyclicalProduct.elec &&
                !isCyclicalProduct.gas
                    ? 1
                    : 0
            ],
        },
    ];
};

export const switchingSupplierOptions: Array<SelectOption<
    EEffectiveStartRanges
>> = [
    {
        id: EEffectiveStartRanges.NOW,
        value: EEffectiveStartRanges.NOW,
        label: Wording.now,
    },
    {
        id: EEffectiveStartRanges.LATER,
        value: EEffectiveStartRanges.LATER,
        label: Wording.later,
    },
];

const initialDateValues = {
    changingSupplierStartTime: null,
    electricityExpress: false,
    electricityFirstPaymentCB: false,
    electricityTimeslots: null,
    electricityTimeslotSelection: null,
    gasExpress: false,
    gasFirstPaymentCB: false,
    gasTimeslots: null,
    gasTimeslotSelection: null,
    movingInElectricityDatePick: null,
    movingInGasDatePick: null,
};
const initialState: IState = {
    ...initialDateValues,
    addressValue: '',
    debitDay: '',
    addressCoords: undefined,
    civilitySelection: null,
    currentProcessTypeSelection: EProcessTypes.MOVE_IN,
    electricityAppointments: [],
    firstNameValue: '',
    gasAppointments: [],
    IBANValue: '',
    identicalAddressToggleState: true,
    isJointContract: false,
    paymentModeSelection: null,
    postalCodeSelection: null,
    lastNameValue: '',
};

class SituationSection extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = { ...initialState, debitDay: WordingDebitDay.paymentMethod.debitDaysEnum[0] };
        this.handleAddressInput = this.handleAddressInput.bind(this);
        this.handleCoordsInput = this.handleCoordsInput.bind(this);
        this.handleIBANInput = this.handleIBANInput.bind(this);
        this.handleIdenticalAddressToggle = this.handleIdenticalAddressToggle.bind(
            this,
        );
        this.handleJointContractToggle = this.handleJointContractToggle.bind(
            this,
        );
        this.handleMovingInDateCalenderOptions = this.handleMovingInDateCalenderOptions.bind(
            this,
        );
        this.handleMovingInDatePickerPick = this.handleMovingInDatePickerPick.bind(
            this,
        );
        this.handlePaymentModeSelection = this.handlePaymentModeSelection.bind(
            this,
        );
        this.handlePostalCodeSelection = this.handlePostalCodeSelection.bind(
            this,
        );
        this.handleSituationToggle = this.handleSituationToggle.bind(this);
        this.handleSwitchingSupplierContractStartTimeSelection = this.handleSwitchingSupplierContractStartTimeSelection.bind(
            this,
        );
        this.handleTimeslotSelection = this.handleTimeslotSelection.bind(this);
        this.validate = _.throttle(this.validate, 1000);
        this.handleDebitDay = this.handleDebitDay.bind(this);
     }

    componentDidMount() {
        this.init();

        const { cyclicalProducts, siteOptionSelection } = this.props;

        const { electricityFirstPaymentCB, gasFirstPaymentCB } = this.state;

        const isCyclicalProductElec = _.includes<EEnergyTypes>(
            cyclicalProducts,
            EEnergyTypes.ELECTRICTY,
        );
        const isCyclicalProductGas = _.includes<EEnergyTypes>(
            cyclicalProducts,
            EEnergyTypes.GAS,
        );

        const energyTypes: EEnergyTypes[] = toEnergies(siteOptionSelection);
        const paymentModeSelection = preparePaymentModOptions({
            cyclicalProducts,
            energyTypes,
            firstPaymentCB: {
                elec: electricityFirstPaymentCB,
                gas: gasFirstPaymentCB,
            },
            isCyclicalProduct: {
                elec: isCyclicalProductElec,
                gas: isCyclicalProductGas,
            },
        })[0];

        this.setState({
            paymentModeSelection,
        });
    }

    async init() {
        const {
            initialParams,
            cyclicalProducts,
            siteOptionSelection,
        } = this.props;

        if (!!initialParams) {
            let movingInParams: MovingInParams | {} = {};
            let switchParams: SwitchParams | {} = {};
            let changingSupplierStartTime: SelectOption<
                EEffectiveStartRanges
            > | null = null;
            const currentProcessTypeSelection: EProcessTypes =
                _.get(initialParams, 'startDate.processType') ||
                EProcessTypes.MOVE_IN;
            const identicalAddressToggleState: boolean = !initialParams.billingAddress;
            const addressValue: string = getAddressStreetLabel(
                initialParams.billingAddress,
            );
            const addressCoords = initialParams.coords || {
                civility: ECivilities.MR,
                firstName: '',
                lastName: '',
            };
            const postalCodeSelection: TAddress | null =
                initialParams.billingAddress || null;
            let civilitySelection: ECivilities | null = ECivilities.MR;
            let lastNameValue: string = '';
            let firstNameValue: string = '';
            const isJointContract: boolean = !!initialParams.thirdParty;
            if (isJointContract) {
                civilitySelection = _.get(
                    initialParams,
                    'thirdParty.civility',
                    null,
                );
                lastNameValue = _.get(initialParams, 'thirdParty.lastName', '');
                firstNameValue = _.get(
                    initialParams,
                    'thirdParty.firstName',
                    '',
                );
            }

            let paymentModeSelection: SelectOption<EPaymentModes> | null = null;
            const IBANValue: string = initialParams.payment.data.iban || '';
            if (initialParams.startDate.processType === EProcessTypes.MOVE_IN) {
                movingInParams = await this.parsePreviousMovingInParams(
                    initialParams,
                );

                const energyTypes: EEnergyTypes[] = toEnergies(
                    siteOptionSelection,
                );

                const isCyclicalProductElec = _.includes<EEnergyTypes>(
                    cyclicalProducts,
                    EEnergyTypes.ELECTRICTY,
                );
                const isCyclicalProductGas = _.includes<EEnergyTypes>(
                    cyclicalProducts,
                    EEnergyTypes.GAS,
                );

                paymentModeSelection =
                    preparePaymentModOptions({
                        cyclicalProducts,
                        energyTypes,
                        firstPaymentCB: {
                            elec: (movingInParams as any)
                                .electricityFirstPaymentCB,
                            gas: (movingInParams as any).gasFirstPaymentCB,
                        },
                        isCyclicalProduct: {
                            elec: isCyclicalProductElec,
                            gas: isCyclicalProductGas,
                        },
                    })[0] || null;
            } else if (
                initialParams.startDate.processType === EProcessTypes.SWITCH
            ) {
                switchParams = this.parsePreviousSwitchParams(initialParams);

                const initialRange: EEffectiveStartRanges | null = _.get(
                    initialParams,
                    'startDate.switchData.startRange',
                    null,
                );
                changingSupplierStartTime =
                    _.find(
                        switchingSupplierOptions,
                        (range: SelectOption<EEffectiveStartRanges>) => {
                            return range.value === initialRange;
                        },
                    ) || null;

                paymentModeSelection =
                    _.find(
                        paymentModeOptions,
                        (mode: SelectOption<EPaymentModes>) => {
                            return initialParams.payment.mode === mode.value;
                        },
                    ) || null;
            }

            const nextState: IState = {
                addressValue,
                addressCoords,
                changingSupplierStartTime,
                civilitySelection,
                currentProcessTypeSelection,
                firstNameValue,
                IBANValue,
                identicalAddressToggleState,
                isJointContract,
                lastNameValue,
                paymentModeSelection,
                postalCodeSelection,
                ...(movingInParams as MovingInParams),
                ...switchParams,
            };

            await setState(this, { ...nextState });
            this.validate();
        }

        const params = parseQueryParams(
            document.location,
            window.location.host,
            window.location.protocol,
        );

        const coholderCivility = params.get('coholderCivility');
        const coholderFirstName = params.get('coholderFirstName');
        const coholderLastName = params.get('coholderLastName');

        const isJointContract =
            !!coholderCivility && !!coholderFirstName && !!coholderLastName;

        if (isJointContract) {
            await setState(this, {
                civilitySelection: coholderCivility as ECivilities,
                lastNameValue: coholderLastName as string,
                firstNameValue: coholderFirstName as string,
                isJointContract,
            });
        }

        await this.fetchAppointments();
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        const {
            isIndividual: prevIsIndividual,
            siteOptionSelection: prevSiteOptionSelection,
        } = prevProps;
        const {
            movingInElectricityDatePick: prevMovingInElectricityDatePick,
            movingInGasDatePick: prevMovingInGasDatePick,
            currentProcessTypeSelection,
        } = prevState;
        const { isIndividual, siteOptionSelection } = this.props;
        const { movingInElectricityDatePick, movingInGasDatePick } = this.state;

        const isSwitch = currentProcessTypeSelection === EProcessTypes.SWITCH;
        const shouldUpdateelectricityTimeslots =
            !!movingInElectricityDatePick &&
            movingInElectricityDatePick !== prevMovingInElectricityDatePick;
        const shouldUpdategasTimeslots =
            !!movingInGasDatePick &&
            movingInGasDatePick !== prevMovingInGasDatePick;
        if (
            !isSwitch &&
            (prevIsIndividual !== isIndividual ||
                prevSiteOptionSelection !== siteOptionSelection)
        ) {
            this.fetchAppointments();
        }
        if (
            !isSwitch &&
            (shouldUpdateelectricityTimeslots || shouldUpdategasTimeslots)
        ) {
            this.fetchTimeSlots(
                shouldUpdateelectricityTimeslots,
                shouldUpdategasTimeslots,
            );
        }
        if (!_.isEqual(prevState, this.state)) {
            this.validate();
        }
    }

    async fetchAppointments() {
        const {
            getCalendarAppointment,
            isIndividual,
            siteOptionSelection,
        } = this.props;
        const nextState: any = {};
        const shouldFetchElectricityAppointements =
            siteOptionSelection === ESubscriptionTypes.ELECTRICITY ||
            siteOptionSelection === ESubscriptionTypes.ELECTRICITY_AND_GAS;
        const shouldFetchGasAppointments =
            siteOptionSelection === ESubscriptionTypes.GAS ||
            siteOptionSelection === ESubscriptionTypes.ELECTRICITY_AND_GAS;
        if (shouldFetchElectricityAppointements) {
            const appointments:
                | TAppointmentDay[]
                | RequestError = await getCalendarAppointment(
                EEnergyTypes.ELECTRICTY,
                isIndividual,
            );
            if (!(appointments instanceof RequestError)) {
                nextState.electricityAppointments = appointments;
            }
        }
        if (shouldFetchGasAppointments) {
            const appointments = await getCalendarAppointment(
                EEnergyTypes.GAS,
                isIndividual,
            );
            if (!(appointments instanceof RequestError)) {
                nextState.gasAppointments = appointments;
            }
        }
        this.setState({ ...nextState });
    }

    async fetchTimeSlots(electricity: boolean, gas: boolean) {
        const { searchAppointmentTimeslot } = this.props;
        const { movingInElectricityDatePick, movingInGasDatePick } = this.state;
        const nextState: any = {};
        try {
            if (!!movingInElectricityDatePick && electricity) {
                const electricityTimeslots = await searchAppointmentTimeslot(
                    movingInElectricityDatePick,
                    EEnergyTypes.ELECTRICTY,
                );
                if (!(electricityTimeslots instanceof RequestError)) {
                    nextState.electricityTimeslots = electricityTimeslots as ETimeslots[];
                }
            }
            if (!!movingInGasDatePick && gas) {
                const gasTimeslots = await searchAppointmentTimeslot(
                    movingInGasDatePick,
                    EEnergyTypes.GAS,
                );
                if (!(gasTimeslots instanceof RequestError)) {
                    nextState.gasTimeslots = gasTimeslots as ETimeslots[];
                }
            }
            this.setState({ ...nextState });
        } catch (err) {
            console.error(err);
        }
    }

    handleAddressInput(address: string) {
        this.setState({ addressValue: address });
    }

    handleCoordsInput(coords: {
        civility: ECivilities | null;
        firstName: string;
        lastName: string;
    }) {
        this.setState({
            civilitySelection: coords.civility,
            firstNameValue: coords.firstName,
            lastNameValue: coords.lastName,
        });
    }

    handleIBANInput(e: ChangeEvent<HTMLInputElement>) {
        const nextValue = _.get(e, 'target.value', '');
        this.setState({ IBANValue: nextValue });
    }

    handleIdenticalAddressToggle(identical: boolean) {
        this.setState({ identicalAddressToggleState: identical });
    }

    handleJointContractToggle(toggled: boolean) {
        this.setState({
            civilitySelection: !!toggled ? ECivilities.MR : null,
            isJointContract: toggled,
        });
    }

    handleMovingInDateCalenderOptions(
        energy: 'EL' | 'NG',
        status: 'disabled' | 'selected',
    ): Modifier[] {
        const {
            electricityAppointments,
            gasAppointments,
            currentProcessTypeSelection,
            changingSupplierStartTime,
        } = this.state;
        const { channel } = this.props;
        const isSwitch = currentProcessTypeSelection === EProcessTypes.SWITCH;
        let appointments: TAppointmentDay[] = [];
        if (energy === 'EL') {
            appointments = electricityAppointments;
        } else {
            appointments = gasAppointments;
        }
        let modifiers: Modifier[] = [];

        if (isSwitch && changingSupplierStartTime) {
            if (status === 'disabled') {
                const switchDropdownValues = (() => {
                    let day =
                        config.DefaultSwitchDueDate[
                            changingSupplierStartTime.value
                        ][energy];
                    day = config.ChannelsDoorToDoor.includes(
                        channel.toUpperCase(),
                    )
                        ? (day += 7)
                        : (day += 0);
                    day =
                        !config.ChannelsDoorToDoor.includes(
                            channel.toUpperCase(),
                        ) && changingSupplierStartTime.value === 'LATER'
                            ? (day += 3)
                            : (day += 1);
                    const actualDate = new Date();
                    return addDays(actualDate, day);
                })();
                return [
                    {
                        before: switchDropdownValues,
                        after: addMonth(new Date(), 1),
                    },
                    {
                        daysOfWeek: [0, 6],
                    },
                ];
            }

            return [];
        }

        if (status === 'disabled' && appointments.length === 0) {
            const today: Date = new Date(Date.now());
            modifiers = [
                {
                    after: today,
                    before: today,
                },
                today,
            ];
        } else if (status === 'selected') {
            modifiers = filterAppointments(appointments, 'express', true);
        } else {
            modifiers = filterAppointments(appointments, 'available', false);
        }
        return modifiers;
    }

    handleMovingInDatePickerPick(d: Date, energy: 'EL' | 'NG') {
        const {
            electricityAppointments,
            gasAppointments,
            currentProcessTypeSelection,
        } = this.state;
        const { cyclicalProducts } = this.props;

        const isSwitch = currentProcessTypeSelection === EProcessTypes.SWITCH;

        const nextState: any = {};
        let appointments: TAppointmentDay[] = [];
        const isCyclicalProductElec = _.includes<EEnergyTypes>(
            cyclicalProducts,
            EEnergyTypes.ELECTRICTY,
        );
        const isCyclicalProductGas = _.includes<EEnergyTypes>(
            cyclicalProducts,
            EEnergyTypes.GAS,
        );
        if (energy === EEnergyTypes.ELECTRICTY) {
            nextState.movingInElectricityDatePick = d;
            appointments = electricityAppointments;
        } else if (energy === EEnergyTypes.GAS) {
            nextState.movingInGasDatePick = d;
            appointments = gasAppointments;
        }

        if (!isSwitch) {
            const match = matchDate(appointments, d);
            const nextFirstPaymentCB = !!match && !!match.firstPaymentCB;
            if (energy === EEnergyTypes.ELECTRICTY) {
                nextState.electricityExpress = !!match && !!match.express;
                nextState.electricityFirstPaymentCB =
                    nextFirstPaymentCB && !isCyclicalProductElec;
            } else if (energy === EEnergyTypes.GAS) {
                nextState.gasExpress = !!match && !!match.express;
                nextState.gasFirstPaymentCB =
                    nextFirstPaymentCB && !isCyclicalProductGas;
            }
        }

        this.setState({ ...nextState });
    }

    handlePaymentModeSelection(e: SelectOption<EPaymentModes>) {
        this.setState({ paymentModeSelection: e });
    }

    handlePostalCodeSelection(address: TAddress) {
        this.setState({ postalCodeSelection: address });
    }

    handleSituationToggle(situation: EProcessTypes) {
        const { cyclicalProducts, siteOptionSelection, channel } = this.props;

        const { electricityFirstPaymentCB, gasFirstPaymentCB } = this.state;

        const isCyclicalProductElec = _.includes<EEnergyTypes>(
            cyclicalProducts,
            EEnergyTypes.ELECTRICTY,
        );
        const isCyclicalProductGas = _.includes<EEnergyTypes>(
            cyclicalProducts,
            EEnergyTypes.GAS,
        );

        const energyTypes: EEnergyTypes[] = toEnergies(siteOptionSelection);
        const paymentModeSelection = preparePaymentModOptions({
            cyclicalProducts,
            energyTypes,
            firstPaymentCB: {
                elec: electricityFirstPaymentCB,
                gas: gasFirstPaymentCB,
            },
            isCyclicalProduct: {
                elec: isCyclicalProductElec,
                gas: isCyclicalProductGas,
            },
        })[0];

        const prepareDateValue = checkIsDisableSwitchSelect({ channel })
            ? {}
            : initialDateValues;

        this.setState({
            ...prepareDateValue,
            currentProcessTypeSelection: situation,
            paymentModeSelection,
        });
    }

    handleSwitchingSupplierContractStartTimeSelection(
        startTime: SelectOption<EEffectiveStartRanges>,
    ) {
        const { siteOptionSelection } = this.props;
        if (!!_.find(switchingSupplierOptions, startTime)) {
            const { DefaultSwitchDueDate } = config;

            const getDateByEnergy = (energy: 'EL' | 'NG') =>
                addDays(
                    new Date(),
                    DefaultSwitchDueDate[startTime.value][energy],
                );

            const nextDateProps: any = {};

            if (siteOptionSelection === ESubscriptionTypes.ELECTRICITY) {
                nextDateProps.movingInElectricityDatePick = getDateByEnergy(
                    'EL',
                );
            }

            if (siteOptionSelection === ESubscriptionTypes.GAS) {
                nextDateProps.movingInGasDatePick = getDateByEnergy('NG');
            }

            if (
                siteOptionSelection === ESubscriptionTypes.ELECTRICITY_AND_GAS
            ) {
                nextDateProps.movingInGasDatePick = getDateByEnergy('NG');
                nextDateProps.movingInElectricityDatePick = getDateByEnergy(
                    'EL',
                );
            }

            this.setState({
                changingSupplierStartTime: startTime,
                ...nextDateProps,
            });
        } else {
            console.error('Unexpected start time value');
        }
    }

    handleTimeslotSelection(
        opt: SelectOption<ETimeslots>,
        energy: 'EL' | 'NG',
    ) {
        const nextState: any = {};
        const timeslot: ETimeslots = _.get(opt, 'value', null) as ETimeslots;
        if (energy === 'EL') {
            nextState.electricityTimeslotSelection = timeslot;
        } else if (energy === 'NG') {
            nextState.gasTimeslotSelection = timeslot;
        }
        this.setState({ ...nextState });
    }

    async parsePreviousMovingInParams(
        initialParams: Data,
    ): Promise<MovingInParams> {
        const hasElectricity: boolean = _.includes(
            initialParams.energies,
            EEnergyTypes.ELECTRICTY,
        );
        const hasGas: boolean = _.includes(
            initialParams.energies,
            EEnergyTypes.GAS,
        );
        let electricityAppointments: TAppointmentDay[] = [];
        let electricityExpress: boolean = false;
        let electricityFirstPaymentCB: boolean = false;
        let electricityTimeslots: ETimeslots[] | null = null;
        let electricityTimeslotSelection: ETimeslots | null = null;
        let gasAppointments: TAppointmentDay[] = [];
        let gasExpress: boolean = false;
        let gasFirstPaymentCB: boolean = false;
        let gasTimeslots: ETimeslots[] | null = null;
        let gasTimeslotSelection: ETimeslots | null = null;
        let movingInElectricityDatePick: Date | null = null;
        let movingInGasDatePick: Date | null = null;
        if (hasElectricity) {
            const electricityParams = await this.parsePreviousEnergyMovingInParams(
                initialParams,
                EEnergyTypes.ELECTRICTY,
            );
            electricityAppointments = electricityParams.appointments;
            electricityExpress = electricityParams.express;
            electricityFirstPaymentCB = electricityParams.firstPaymentCB;
            electricityTimeslots = electricityParams.timeslots;
            electricityTimeslotSelection = electricityParams.timeslotSelection;
            movingInElectricityDatePick = electricityParams.datePick;
        }
        if (hasGas) {
            const gasParams = await this.parsePreviousEnergyMovingInParams(
                initialParams,
                EEnergyTypes.GAS,
            );
            gasAppointments = gasParams.appointments;
            gasExpress = gasParams.express;
            gasFirstPaymentCB = gasParams.firstPaymentCB;
            gasTimeslots = gasParams.timeslots;
            gasTimeslotSelection = gasParams.timeslotSelection;
            movingInGasDatePick = gasParams.datePick;
        }
        return {
            electricityAppointments,
            electricityExpress,
            electricityFirstPaymentCB,
            electricityTimeslots,
            electricityTimeslotSelection,
            gasAppointments,
            gasExpress,
            gasFirstPaymentCB,
            gasTimeslots,
            gasTimeslotSelection,
            movingInElectricityDatePick,
            movingInGasDatePick,
        };
    }

    async parsePreviousEnergyMovingInParams(
        initialParams: Data,
        energy: EEnergyTypes,
    ): Promise<{
        appointments: TAppointmentDay[];
        datePick: Date | null;
        express: boolean;
        firstPaymentCB: boolean;
        timeslots: ETimeslots[] | null;
        timeslotSelection: ETimeslots | null;
    }> {
        const {
            isIndividual,
            getCalendarAppointment,
            searchAppointmentTimeslot,
        } = this.props;

        let appointments: TAppointmentDay[] = [];
        let datePick: Date | null = null;
        let express: boolean = false;
        let firstPaymentCB: boolean = false;
        let timeslots: ETimeslots[] | null = null;
        let timeslotSelection: ETimeslots | null = null;
        const appointmentsRes = await getCalendarAppointment(
            energy,
            isIndividual,
            true,
        );
        appointments = !(appointmentsRes instanceof RequestError)
            ? appointmentsRes
            : [];
        const previousDatePick: Date | null = _.get(
            initialParams,
            `startDate.movingInData.${energy}.date`,
            null,
        );
        const previousTimeslot: ETimeslots | null = _.get(
            initialParams,
            `startDate.movingInData.${energy}.timeslot`,
            null,
        );
        let appointmentDay: TAppointmentDay | null = null;
        if (!!previousDatePick) {
            const previousAppDate: Date | number = new Date(previousDatePick);
            const previousAppDateStr: string = getFormattedDateToBackEnd(
                previousAppDate,
            );
            appointmentDay =
                _.find(appointments, (app: TAppointmentDay) => {
                    const appDate: Date | number = new Date(app.date);
                    if (!previousAppDate || !appDate) return false;
                    const appDateStr: string = getFormattedDateToBackEnd(
                        appDate,
                    );
                    return previousAppDateStr === appDateStr;
                }) || null;
        }
        if (!!appointmentDay) {
            datePick = new Date(appointmentDay.date);
            express = !!appointmentDay.express;
            firstPaymentCB = !!appointmentDay.firstPaymentCB;
            const timeslotsRes:
                | ETimeslots[]
                | RequestError = await searchAppointmentTimeslot(
                datePick,
                energy,
            );
            if (!(timeslotsRes instanceof RequestError)) {
                timeslots = timeslotsRes;
                const previousTimeslot: ETimeslots | null = _.get(
                    initialParams,
                    `startDate.movingInData.${energy}.timeslot`,
                    null,
                );
                timeslotSelection =
                    _.find(timeslots, (timeslot: ETimeslots) => {
                        return previousTimeslot === timeslot;
                    }) || null;
            }
        }
        // if !datePick Breach: Missing express and firstPaymetnCB values ! ! !
        if (!datePick) {
            datePick = previousDatePick;
            timeslotSelection = previousTimeslot;
        }

        return {
            appointments,
            datePick,
            express,
            firstPaymentCB,
            timeslots,
            timeslotSelection,
        };
    }

    parsePreviousSwitchParams(initialParams: Data): SwitchParams {
        const switchData: any = {};

        const hasElectricity: boolean = _.includes(
            initialParams.energies,
            EEnergyTypes.ELECTRICTY,
        );
        const hasGas: boolean = _.includes(
            initialParams.energies,
            EEnergyTypes.GAS,
        );

        if (hasElectricity) {
            const nextDate = _.get(
                initialParams,
                `startDate.switchData.EL.dueDate`,
                null,
            );

            if (!!nextDate) {
                switchData.movingInElectricityDatePick = nextDate;
            }
        }

        if (hasGas) {
            const nextDate = _.get(
                initialParams,
                `startDate.switchData.NG.dueDate`,
                null,
            );

            if (!!nextDate) {
                switchData.movingInGasDatePick = nextDate;
            }
        }

        return Object.keys(switchData).length !== 0 ? switchData : {};
    }

    validate() {
        const {
            onValidationStatusChange,
            siteOptionSelection,
            cyclicalProducts,
        } = this.props;
        const {
            addressCoords,
            addressValue,
            changingSupplierStartTime,
            civilitySelection,
            currentProcessTypeSelection,
            electricityExpress,
            electricityFirstPaymentCB,
            electricityTimeslotSelection,
            firstNameValue,
            gasExpress,
            gasFirstPaymentCB,
            gasTimeslotSelection,
            IBANValue,
            identicalAddressToggleState,
            isJointContract,
            lastNameValue,
            movingInElectricityDatePick,
            movingInGasDatePick,
            paymentModeSelection,
            postalCodeSelection,
        } = this.state;

        const isCyclicalProductElec = _.includes<EEnergyTypes>(
            cyclicalProducts,
            EEnergyTypes.ELECTRICTY,
        );
        const isCyclicalProductGas = _.includes<EEnergyTypes>(
            cyclicalProducts,
            EEnergyTypes.GAS,
        );

        let hasValidInput =
                paymentModeSelection !==
                null /* && TODO Uncomment when possible IBANValue.length > 0*/;
        if (!identicalAddressToggleState) {
            hasValidInput =
                hasValidInput &&
                addressValue.length > 0 &&
                postalCodeSelection !== null;
        }
        if (isJointContract) {
            hasValidInput =
                hasValidInput &&
                civilitySelection !== null &&
                firstNameValue.length > 0 &&
                lastNameValue.length > 0;
        }
        if (currentProcessTypeSelection === EProcessTypes.MOVE_IN) {
            if (
                siteOptionSelection === ESubscriptionTypes.ELECTRICITY_AND_GAS
            ) {
                hasValidInput =
                    hasValidInput &&
                    movingInElectricityDatePick !== null &&
                    electricityTimeslotSelection !== null &&
                    movingInGasDatePick !== null &&
                    gasTimeslotSelection !== null;
            } else if (siteOptionSelection === ESubscriptionTypes.ELECTRICITY) {
                hasValidInput =
                    hasValidInput &&
                    movingInElectricityDatePick !== null &&
                    electricityTimeslotSelection !== null;
            } else if (siteOptionSelection === ESubscriptionTypes.GAS) {
                hasValidInput =
                    hasValidInput &&
                    movingInGasDatePick !== null &&
                    gasTimeslotSelection !== null;
            }
        } else if (currentProcessTypeSelection === EProcessTypes.SWITCH) {
            hasValidInput = hasValidInput && changingSupplierStartTime !== null;

            if (
                siteOptionSelection === ESubscriptionTypes.ELECTRICITY_AND_GAS
            ) {
                hasValidInput =
                    hasValidInput &&
                    movingInElectricityDatePick !== null &&
                    movingInGasDatePick !== null;
            } else if (siteOptionSelection === ESubscriptionTypes.ELECTRICITY) {
                hasValidInput =
                    hasValidInput && movingInElectricityDatePick !== null;
            } else if (siteOptionSelection === ESubscriptionTypes.GAS) {
                hasValidInput = hasValidInput && movingInGasDatePick !== null;
            }
        }
        let effectiveStartRange: EEffectiveStartRanges | undefined;
        let electricityEffectiveStartDate: Date | undefined;
        let gasEffectiveStartDate: Date | undefined;
        let electricityTimeslot: ETimeslots | undefined;
        let gasTimeslot: ETimeslots | undefined;
        let billingAddress: TAddress | undefined;
        let iban: string | undefined;
        let processType: EProcessTypes | undefined;
        let electricityDueDate: Date | undefined;
        let gasDueDate: Date | undefined;
        let thirdParty:
            | {
                  civility: ECivilities;
                  firstName: string;
                  lastName: string;
              }
            | undefined;
        if (hasValidInput) {
            billingAddress = !!postalCodeSelection
                ? { ...postalCodeSelection, street: addressValue }
                : undefined;
            iban = IBANValue;
            processType = currentProcessTypeSelection;
            if (!!civilitySelection) {
                thirdParty = {
                    civility: civilitySelection,
                    lastName: lastNameValue,
                    firstName: firstNameValue,
                };
            }
            if (currentProcessTypeSelection === EProcessTypes.MOVE_IN) {
                electricityEffectiveStartDate =
                    movingInElectricityDatePick || undefined;
                gasEffectiveStartDate = movingInGasDatePick || undefined;
                electricityTimeslot = electricityTimeslotSelection || undefined;
                gasTimeslot = gasTimeslotSelection || undefined;
            } else if (
                currentProcessTypeSelection === EProcessTypes.SWITCH &&
                changingSupplierStartTime !== null
            ) {
                effectiveStartRange = changingSupplierStartTime.value;
                if (
                    changingSupplierStartTime.value ===
                    EEffectiveStartRanges.LATER
                ) {
                    (electricityEffectiveStartDate = isCyclicalProductElec
                        ? new Date()
                        : (movingInElectricityDatePick as Date)),
                        'EL',
                        'LATER',
                        (gasEffectiveStartDate = isCyclicalProductGas
                            ? new Date()
                            : (movingInGasDatePick as Date)),
                        'NG',
                        'LATER',
                        (gasDueDate = movingInGasDatePick as Date);
                    electricityDueDate = movingInElectricityDatePick as Date;
                    if (
                        electricityDueDate &&
                        electricityDueDate.setHours(0, 0, 0, 0) ===
                            new Date().setHours(0, 0, 0, 0)
                    ) {
                        electricityEffectiveStartDate = undefined;
                        gasEffectiveStartDate = undefined;
                    } else if (
                        gasDueDate &&
                        gasDueDate.setHours(0, 0, 0, 0) ===
                            new Date().setHours(0, 0, 0, 0)
                    ) {
                        electricityEffectiveStartDate = undefined;
                        gasEffectiveStartDate = undefined;
                    } else {
                        if (!isCyclicalProductGas) {
                            gasEffectiveStartDate = addDays(
                                gasEffectiveStartDate,
                                21,
                            );
                        } else {
                            gasEffectiveStartDate = gasDueDate;
                        }
                        if (!isCyclicalProductElec) {
                            electricityEffectiveStartDate = addDays(
                                electricityEffectiveStartDate,
                                21,
                            );
                        } else {
                            electricityEffectiveStartDate = electricityDueDate;
                        }
                    }
                } else if (
                    changingSupplierStartTime.value ===
                    EEffectiveStartRanges.NOW
                ) {
                    (electricityEffectiveStartDate = movingInElectricityDatePick as Date),
                        'EL',
                        'NOW',
                        (gasEffectiveStartDate = movingInGasDatePick as Date),
                        'NG',
                        'NOW',
                        (electricityDueDate = movingInElectricityDatePick as Date);
                    gasDueDate = movingInGasDatePick as Date;
                    if (
                        electricityDueDate &&
                        electricityDueDate.setHours(0, 0, 0, 0) ===
                            new Date().setHours(0, 0, 0, 0)
                    ) {
                        electricityEffectiveStartDate = undefined;
                        gasEffectiveStartDate = undefined;
                    } else if (
                        gasDueDate &&
                        gasDueDate.setHours(0, 0, 0, 0) ===
                            new Date().setHours(0, 0, 0, 0)
                    ) {
                        electricityEffectiveStartDate = undefined;
                        gasEffectiveStartDate = undefined;
                    } else {
                        gasEffectiveStartDate = gasDueDate;
                        electricityEffectiveStartDate = electricityDueDate;
                    }
                }
            }
        }

        onValidationStatusChange(hasValidInput, {
            coords: addressCoords,
            effectiveStartRange,
            electricityEffectiveStartDate,
            gasEffectiveStartDate,
            electricityExpress,
            gasExpress,
            electricityTimeslot,
            gasTimeslot,
            electricityFirstPaymentCB,
            gasFirstPaymentCB,
            billingAddress,
            iban,
            processType,
            thirdParty,
            electricityDueDate,
            gasDueDate,
        });
    }

   /* handleDebitDay(value: string) {
       this.setState({
           debitDay: value,
       })
    }*/

    handleDebitDay(selectedDay: any) {
        this.setState({ debitDay: selectedDay });
    }

    render() {
        const {
            cyclicalProducts,
            initialParams,
            siteOptionSelection,
            channel,
        } = this.props;
        const {
            addressCoords,
            changingSupplierStartTime,
            civilitySelection,
            currentProcessTypeSelection,
            electricityFirstPaymentCB,
            electricityTimeslots,
            electricityTimeslotSelection,
            firstNameValue,
            gasFirstPaymentCB,
            gasTimeslots,
            gasTimeslotSelection,
            IBANValue,
            isJointContract,
            movingInElectricityDatePick,
            movingInGasDatePick,
            lastNameValue,
            paymentModeSelection,
            debitDay,
        } = this.state;
        const energyTypes: EEnergyTypes[] = toEnergies(siteOptionSelection);
        const isCyclicalProductElec = _.includes<EEnergyTypes>(
            cyclicalProducts,
            EEnergyTypes.ELECTRICTY,
        );
        const isCyclicalProductGas = _.includes<EEnergyTypes>(
            cyclicalProducts,
            EEnergyTypes.GAS,
        );
        const isLaterSDD =
            changingSupplierStartTime !== null &&
            changingSupplierStartTime.value === 'LATER';
        const dateContract: any = toDateContract(
            {
                // date: (isLaterSDD && config.ChannelsDoorToDoor.includes(channel.toUpperCase()) ? addDays(movingInElectricityDatePick as Date, 7) : movingInElectricityDatePick),
                date: movingInElectricityDatePick,
                firstPaymentCB: electricityFirstPaymentCB,
                isCyclicalProduct: isCyclicalProductElec,
                startTime: _.get(changingSupplierStartTime, 'value', null),
                timeslots: electricityTimeslots,
                timeslotSelection: electricityTimeslotSelection,
            },
            {
                // date: (isLaterSDD && config.ChannelsDoorToDoor.includes(channel.toUpperCase()) ? addDays(movingInGasDatePick as Date, 7) : movingInGasDatePick),
                date: movingInGasDatePick,
                firstPaymentCB: gasFirstPaymentCB,
                isCyclicalProduct: isCyclicalProductGas,
                startTime: _.get(changingSupplierStartTime, 'value', null),
                timeslots: gasTimeslots,
                timeslotSelection: gasTimeslotSelection,
            },
        );
        const initialAddress: TAddress | undefined =
            _.get(initialParams, 'billingAddress') || undefined;
        const initialCoords: Coords | undefined =
            _.get(initialParams, 'coords') || undefined;

        const paymentModeOptions = preparePaymentModOptions({
            cyclicalProducts,
            energyTypes,
            firstPaymentCB: {
                elec: electricityFirstPaymentCB,
                gas: gasFirstPaymentCB,
            },
            isCyclicalProduct: {
                elec: isCyclicalProductElec,
                gas: isCyclicalProductGas,
            },
        });



        return (
            <section className={baseClassName}>
                <h2>{Wording.title}</h2>
                <div className={bcn([baseClassName, radioToggleClassName])}>
                    <CustomRadio
                        text={Wording.movingIn}
                        value={EProcessTypes.MOVE_IN}
                        checked={
                            currentProcessTypeSelection ===
                            EProcessTypes.MOVE_IN
                        }
                        handleCheck={() => {
                            this.handleSituationToggle(EProcessTypes.MOVE_IN);
                        }}
                    />
                    <CustomRadio
                        text={Wording.switchingSupplier}
                        value={EProcessTypes.SWITCH}
                        checked={
                            currentProcessTypeSelection === EProcessTypes.SWITCH
                        }
                        handleCheck={() => {
                            this.handleSituationToggle(EProcessTypes.SWITCH);
                            if (isCyclicalProductElec || isCyclicalProductGas) {
                                this.handleSwitchingSupplierContractStartTimeSelection(
                                    switchingSupplierOptions[1],
                                );
                            }
                        }}
                    />
                </div>

                <div className={bcn([baseClassName, detailsClassName])}>
                    <CustomDatePicker
                        channel={channel}
                        isCyclicalProductElec={isCyclicalProductElec}
                        isCyclicalProductGas={isCyclicalProductGas}
                        dateContract={dateContract}
                        dropdownContractOptions={switchingSupplierOptions}
                        dropdownContractOptionSelection={
                            changingSupplierStartTime
                        }
                        energyTypes={energyTypes}
                        handleChangeTimeslotSelect={
                            this.handleTimeslotSelection
                        }
                        handleDateCalendarOption={
                            this.handleMovingInDateCalenderOptions
                        }
                        handleDatePicker={this.handleMovingInDatePickerPick}
                        handleDropdownContract={
                            this
                                .handleSwitchingSupplierContractStartTimeSelection
                        }
                        situation={
                            currentProcessTypeSelection ===
                            EProcessTypes.MOVE_IN
                                ? 'MOVE_IN'
                                : 'SWITCH'
                        }
                    />
                </div>
                <div style={{ marginTop: "30px" }}>
                    <label className="custom-radio" style={{ paddingLeft: "0px" }}>
                        Jour de prélèvement souhaité
                    </label>
                    <div className="profile-container__update-payment-method__debit-day__container" style={{justifyContent: 'flex-start'}}>
                        {WordingDebitDay.paymentMethod.debitDaysEnum.map(dd => (
                            <CustomRadio
                                key={dd}
                                checked={dd === this.state.debitDay}
                                text={replace(
                                    WordingDebitDay.paymentMethod.debitDayLabel,
                                    '{{ddValue}}',
                                    dd
                                )}
                                value={dd}
                                handleCheck={() => {
                                    this.props.handleDebitDay(
                                        (dd as unknown) as number
                                    );
                                    this.handleDebitDay(dd);
                                }}
                            />
                        ))}
                    </div>
                </div>

                {currentProcessTypeSelection === EProcessTypes.SWITCH &&
                    _.get(changingSupplierStartTime, 'value') !==
                    EEffectiveStartRanges.LATER && <p>{Wording.terms}</p>}
                <div
                    className={bcn([
                        baseClassName,
                        billingInformationsClassName,
                    ])}
                >
                    {!isCyclicalProductElec && !isCyclicalProductGas && (
                        <div
                            className={bcn([
                                baseClassName,
                                billingInformationsClassName,
                                'disclaimer',
                            ])}
                        >
                            <p>
                                <span>{Wording.disclaimerHead} </span>
                                <span>{Wording.disclaimerBody}</span>
                            </p>
                        </div>
                    )}
                    <div
                        className={bcn([
                            baseClassName,
                            billingInformationsClassName,
                            'inputs',
                        ])}
                    >
                        {/* <div
                            className={bcn([
                                baseClassName,
                                billingInformationsClassName,
                                'inputs',
                                'payment-mode',
                            ])}
                        >
                            <div
                                className={bcn([
                                    baseClassName,
                                    billingInformationsClassName,
                                    'inputs',
                                    'payment-mode',
                                    'iban',
                                ])}
                            >
                                <CustomInput
                                    onInputChange={this.handleIBANInput}
                                    text={Wording.iban}
                                    value={IBANValue}
                                />
                                <span>{Wording.otherFormat}</span>
                            </div>
                            <CustomSelect
                                handleChangeSelect={
                                    this.handlePaymentModeSelection
                                }
                                options={paymentModeOptions}
                                text={Wording.paymentMode}
                                value={
                                    paymentModeOptions[0] ||
                                    paymentModeSelection
                                }
                            />
                        </div> */}
                        <AddressSelect
                            initialCoords={initialCoords}
                            initialAddress={initialAddress}
                            onAddressInputChange={this.handleAddressInput}
                            onCoordsChange={(addressCoords: Coords) => {
                                this.setState({ addressCoords });
                            }}
                            onIdenticalAddressToggle={
                                this.handleIdenticalAddressToggle
                            }
                            onPostalCodeSelection={
                                this.handlePostalCodeSelection
                            }
                            text={Wording.identicalAddress}
                        />
                        <div
                            className={bcn([
                                baseClassName,
                                billingInformationsClassName,
                                'inputs',
                                'custom-radio-bundle',
                            ])}
                        >
                            <label>{Wording.jointAccount}</label>
                            <CustomRadio
                                text={Wording.yes}
                                value={'true'}
                                checked={isJointContract}
                                handleCheck={() => {
                                    this.handleJointContractToggle(true);
                                }}
                            />
                            <CustomRadio
                                text={Wording.no}
                                value={'false'}
                                checked={!isJointContract}
                                handleCheck={() => {
                                    this.handleJointContractToggle(false);
                                }}
                            />
                        </div>
                        {isJointContract && (
                            <CoordsInputs
                                onInputChange={this.handleCoordsInput}
                                values={{
                                    civility: civilitySelection,
                                    firstName: firstNameValue,
                                    lastName: lastNameValue,
                                }}
                            />
                        )}
                    </div>
                </div>
            </section>
        );
    }
}

export default SituationSection;
