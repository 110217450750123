import React, { ChangeEvent, Component } from 'react';
import _ from 'lodash';
import {
    AddressSelect,
    CoordsInputs,
    CustomDate,
    CustomInput,
    CustomRadio,
    CustomSelect,
    Group,
    PostalCodeSelect,
} from '../../../../../components';
import WordingConstant from '../../../../../utils/wording.json';
import {
    buildClassName as bcn,
    formatFirstNameString,
    formatLastNameString,
    formatSIRETString,
    formatPhoneNumberString,
    getFormattedDateToBackEnd,
    isValidName,
    isValidEmail,
    isValidPhone,
    isValidString,
    isValidBirthDate,
    isValidBirthDatePro,
    isLimitedAuthorization,
    isEmptySelectOption,
    parseQueryParams,
    getAPEFromObject,
} from '../../../../../utils/helpers';
import LegalStatuses from '../../../../../assets/legal_statuses.json';
import {
    ECivilities,
    ECompanyTypes,
    TAddress,
    TCustomerContact,
} from '../../../../../utils/network/types';
import SearchService from '../../../../../utils/search';
import { RequestError } from '../../../../../utils/network/errors';
import { SelectOption, TSchemaProps } from 'Types';
import Data from './Data';
import { setState } from '../../../../../utils/reactHelpers';

import countries from '../../../../../utils/countries.json';

import accents from 'remove-accents';

interface IProps {
    initialParams?: Data;
    isIndividual: boolean;
    onIsIndividualToggle: (is: boolean) => void;
    onValidationStatusChange: (
        validated: boolean,
        customer: TCustomerContact,
    ) => void;
    searchSiret: (value: string) => Promise<any | null>;
    groupList: string[];
    schemaProps: TSchemaProps;
    handleGroupSelection: (group: SelectOption<string>) => void;
}

interface IState {
    firstNameValue: string;
    lastNameValue: string;
    birthDatePick: Date | undefined;
    birthDatePickPro: Date | undefined;
    businessNameValue: string;
    SIRETValue: string;
    mailAddressValue: string;
    phoneNumberValue: string;
    identicalAddressToggleState: boolean;
    addressAddressValue: string;
    address: TAddress | null;
    firstNameError: string;
    lastNameError: string;
    birthDateError: string;
    birthDateErrorPro: string;
    businessNameError: string;
    SIRETError: string;
    mailAddressError: string;
    phoneNumberError: string;
    addressAddressError: string;
    addressError: string;
    civilStatusSelection: ECivilities | null;
    legalStatusSelection: SelectOption<ECompanyTypes>;
    groupSelection: string | SelectOption<any>;
    APE: string;
    defaultGroup: string;
    groupSelected: string;
    groupError: string;
    birthTownPostalCode: string;
    birthTownTownName: string;
    birthTownCountry: string;
}

const Wording = WordingConstant.AdminSubscriptionContainer.ClientData;
const baseClassName = 'client-data-section';
const mainClassName = 'main';
const legalStatuses: Array<SelectOption<ECompanyTypes>> = _.map(
    LegalStatuses as Array<{ code: ECompanyTypes; nom: string }>,
    (status, i) => {
        return {
            id: `${i}`,
            label: `${status.code} - ${status.nom}`,
            value: status.code,
        };
    },
);
const inputRefreshDelay = 500;
const validationDelay = 1000;
const birthDateText: string = `${Wording.inputs.birthDate}`;
const businessNameText: string = `${Wording.inputs.businessName}`;
const legalStatusText: string = `${Wording.inputs.legalStatus}`;
const SIRETText: string = `${Wording.inputs.SIRET}`;
const mailAddressText: string = `${Wording.inputs.mailAddress}`;
const phoneNumberText: string = `${Wording.inputs.phoneNumber}`;

function parseAdressNumber(addressStr: string): string {
    const reg = /^[0-9]+ /;
    const match: string = _.get(reg.exec(addressStr), '0', '');
    return match;
}

function hasError(err: string): boolean {
    return err.replace(/[\s]/g, '').length === 0;
}

const defaultBirthTown = {
    country: '',
    netArea: '',
    number: '',
    postalCode: '',
    street: '',
    townName: '',
};

class ClientDataSection extends Component<IProps, IState> {
    private pendingValidation = null;
    constructor(props: IProps) {
        super(props);

        (this as any).searchService = new SearchService().setRaw(true);

        const { schemaProps } = this.props;
        this.state = {
            firstNameValue: '',
            lastNameValue: '',
            birthDatePick: undefined,
            birthDatePickPro: undefined,
            businessNameValue: '',
            SIRETValue: '',
            mailAddressValue: '',
            phoneNumberValue: '',
            identicalAddressToggleState: true,
            addressAddressValue: '',
            address: null,
            firstNameError: '',
            lastNameError: '',
            birthDateError: '',
            birthDateErrorPro: '',
            businessNameError: '',
            groupError: '',
            SIRETError: '',
            mailAddressError: '',
            phoneNumberError: '',
            addressAddressError: '',
            addressError: '',
            civilStatusSelection: ECivilities.MR,
            legalStatusSelection: legalStatuses[0],
            APE: '',
            defaultGroup: '',
            groupSelected: '',
            birthTownCountry: '',
            birthTownPostalCode: '',
            birthTownTownName: '',

            groupSelection: isLimitedAuthorization(schemaProps)
                ? {
                      id: 0,
                      label: schemaProps.currentGroup,
                      value: schemaProps.currentGroup,
                  }
                : '',
        };

        this.handleBirthTownSelection = this.handleBirthTownSelection.bind(
            this,
        );
        this.handleCoordsInput = this.handleCoordsInput.bind(this);
        this.handleLegalStatusSelection = this.handleLegalStatusSelection.bind(
            this,
        );
        this.handleGroupSelection = this.handleGroupSelection.bind(this);
        this.handlePostalCodeSelection = this.handlePostalCodeSelection.bind(
            this,
        );
        this.handleBirthDatePick = this.handleBirthDatePick.bind(this);
        this.handleBirthDatePickPro = this.handleBirthDatePickPro.bind(this);
        this.handleIdenticalAddressToggle = this.handleIdenticalAddressToggle.bind(
            this,
        );
        this.handleBusinessNameInput = this.handleBusinessNameInput.bind(this);
        this.handleSIRETInput = this.handleSIRETInput.bind(this);
        this.handleMailAddressInput = this.handleMailAddressInput.bind(this);
        this.handlePhoneNumberInput = this.handlePhoneNumberInput.bind(this);
        this.handleAddressInput = this.handleAddressInput.bind(this);
        this.handleBusinessNameChange = this.handleBusinessNameChange.bind(
            this,
        );
        this.handleSIRETChange = this.handleSIRETChange.bind(this);
        this.handleMailAddressChange = this.handleMailAddressChange.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.hasValidInput = this.hasValidInput.bind(this);
        this.handleFirstNameError = _.debounce(
            this.handleFirstNameError.bind(this),
            inputRefreshDelay,
        );
        this.handleLastNameError = _.debounce(
            this.handleLastNameError.bind(this),
            inputRefreshDelay,
        );
        /*this.handleGroupError = _.debounce(
            this.handleGroupError.bind(this),
            inputRefreshDelay
        )*/
        this.handleBusinessNameError = _.debounce(
            this.handleBusinessNameError.bind(this),
            inputRefreshDelay,
        );
        this.handleSIRETError = _.debounce(
            this.handleSIRETError.bind(this),
            inputRefreshDelay,
        ) as any;
        this.handleMailAddressError = _.debounce(
            this.handleMailAddressError.bind(this),
            inputRefreshDelay,
        );
        this.handlePhoneNumberError = _.debounce(
            this.handlePhoneNumberError.bind(this),
            inputRefreshDelay,
        );
        this.handleAddressError = _.debounce(
            this.handleAddressError.bind(this),
            inputRefreshDelay,
        );
        this.validate = _.throttle(this.validate, validationDelay) as any;
    }
    componentDidMount() {
        this.init();

        this.initWithUrl();
    }

    componentWillUnmount = () => {
        (this as any).searchService.unsubscribe();
    };

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        const { initialParams } = this.props;

        if (!_.isEqual(JSON.stringify(prevState), JSON.stringify(this.state))) {
            this.validate();
        }
    }

    async initWithUrl() {
        const { handleGroupSelection, groupList, searchSiret } = this.props;
        const { defaultGroup, groupSelection } = this.state;
        const params = parseQueryParams(
            document.location,
            window.location.host,
            window.location.protocol,
        );

        const checkData = (dt: number | string | undefined) =>
            !!dt ? dt.toString() : '';

        const channel = params.get('channel');

        const civility = params.get('civility');
        const firstName = params.get('firstName');
        const lastName = params.get('lastName');
        const email = params.get('email');
        const phone = params.get('phone');
        const birthdate = params.get('birthdate');

        const identificationNumber = params.get('identificationNumber');
        const activityCode = params.get('activityCode');
        const companyName = params.get('companyName');
        const companyType = params.get('companyType');

        const birthCountry = params.get('birthCountry');
        const birthPostalCode = params.get('birthPostalCode');
        const birthTown = params.get('birthTown');

        const prepareControlledObject: {
            defaultGroup: string;
            groupSelection: string | SelectOption<any>;
            APE: string;
            birthTownTownName: string;
        } = {
            birthTownTownName: checkData(birthTown),
            APE: checkData(activityCode),
            defaultGroup,
            groupSelection,
        };

        if (channel && groupList.includes('channel')) {
            prepareControlledObject.defaultGroup = channel.toString();

            prepareControlledObject.groupSelection = {
                id: 0,
                label: channel as string,
                value: channel as string,
            };
        }

        // call search siret if activity code is empty
        if (!!identificationNumber && !activityCode) {
            const value = formatSIRETString(identificationNumber.toString());
            let res: any;
            if (value.length === 14) {
                res = await searchSiret(value);
                this.handleSIRETError(res);
            }

            prepareControlledObject.APE = getAPEFromObject(res);
        }

        // call getTown if birthTown is undefined
        if (
            !!birthCountry &&
            birthCountry.toString().toUpperCase() === 'FRANCE' &&
            !!birthPostalCode &&
            !birthTown
        ) {
            const res = await (this as any).searchService
                .doSearch(birthPostalCode.toString())
                .toPromise();

            if (res) {
                const obj = res.find(
                    ({ postalCode }: any) =>
                        postalCode === birthPostalCode.toString(),
                );
                prepareControlledObject.birthTownTownName = obj
                    ? obj.townName
                    : '';
            }
        }

        if (!!channel || !!firstName || !!lastName || !!email) {
            await setState(this, {
                SIRETValue: checkData(identificationNumber),
                firstNameValue: checkData(firstName),
                civilStatusSelection: civility as ECivilities,
                lastNameValue: checkData(lastName),
                mailAddressValue: checkData(email),
                phoneNumberValue:
                    String(phone)[0] !== '0' && phone !== ''
                        ? `0${phone}`
                        : String(phone) || '',

                birthDatePick: birthdate ? new Date(birthdate) : undefined,
                birthDatePickPro: birthdate ? new Date(birthdate) : undefined,
                businessNameValue: checkData(companyName),
                legalStatusSelection:
                    _.find(
                        legalStatuses,
                        (status: SelectOption<ECompanyTypes>) => {
                            return companyType === status.value;
                        },
                    ) || legalStatuses[0],
                birthTownCountry: checkData(birthCountry).toUpperCase(),
                birthTownPostalCode: checkData(birthPostalCode),

                ...prepareControlledObject,
            });

            if (!!channel) {
                handleGroupSelection({
                    id: 0,
                    label: channel as string,
                    value: channel as string,
                });
            }
        }
    }

    async init() {
        const { initialParams, handleGroupSelection, schemaProps } = this.props;
        if (!!initialParams) {
            await setState(this, {
                identicalAddressToggleState: !initialParams.address,
            });
            await setState(this, {
                firstNameValue: initialParams.firstNameValue,
                lastNameValue: initialParams.lastNameValue,
                birthDatePick: initialParams.birthDatePick,
                birthDatePickPro: initialParams.birthDatePickPro,
                businessNameValue: initialParams.businessNameValue || '',
                SIRETValue: initialParams.SIRETValue || '',
                mailAddressValue: initialParams.mailAddressValue,
                phoneNumberValue: initialParams.phoneNumberValue,
                identicalAddressToggleState:
                    initialParams.identicalAddressToggleState,
                addressAddressValue: initialParams.addressAddressValue,
                address: initialParams.address,
                civilStatusSelection: initialParams.civilStatus,
                legalStatusSelection:
                    _.find(
                        legalStatuses,
                        (status: SelectOption<ECompanyTypes>) => {
                            return initialParams.legalStatus === status.value;
                        },
                    ) || legalStatuses[0],
                APE: initialParams.APE || '',
                defaultGroup: initialParams.defaultGroup || '',
                groupSelected: this.state.groupSelected || '',
                birthTownCountry:
                    (initialParams.birthTown &&
                        initialParams.birthTown.country) ||
                    '',
                birthTownTownName:
                    (initialParams.birthTown &&
                        initialParams.birthTown.townName) ||
                    '',
                birthTownPostalCode:
                    initialParams.birthTown &&
                    initialParams.birthTown.postalCode
                        ? initialParams.birthTown.postalCode.toString()
                        : '',
                groupSelection: initialParams.defaultGroup
                    ? ({
                          id: 0,
                          label: initialParams.defaultGroup,
                          value: initialParams.defaultGroup,
                      } as SelectOption<string>)
                    : '',
            });

            if (initialParams.defaultGroup !== '') {
                handleGroupSelection({
                    id: 0,
                    label: initialParams.defaultGroup,
                    value: initialParams.defaultGroup,
                });
            }
        }

        if (isLimitedAuthorization(schemaProps)) {
            handleGroupSelection({
                id: 0,
                label: schemaProps.currentGroup,
                value: schemaProps.currentGroup,
            });
        }
    }
    getFirstNameError(value: string): string {
        let error = '';
        if (!isValidString(value)) error = Wording.errors.emptyField;
        else if (!isValidName(value, true)) {
            error = Wording.errors.firstNameValidity;
        }
        return error;
    }
    getLastNameError(value: string): string {
        let error = '';
        if (!isValidString(value)) error = Wording.errors.emptyField;
        else if (!isValidName(value, true)) {
            error = Wording.errors.lastNameValidity;
        }
        return error;
    }
    getBirthDateError(date: Date | undefined): string {
        let error = '';
        if (!isValidBirthDate(date)) error = Wording.errors.birthDateValidity;
        if (!isValidBirthDate(date)) {
            error = Wording.errors.birthConditionValidity;
        }
        return error;
    }
    getBirthDateErrorPro(date: Date | undefined): string {
        let error = '';
        if (!isValidBirthDatePro(date)) error = Wording.errors.birthDateValidity;
        if (!isValidBirthDatePro(date)) {
            error = Wording.errors.birthConditionValidity;
        }
        return error;
    }
    getBusinessNameError(value: string): string {
        let error = '';
        if (!isValidString(value)) error = Wording.errors.emptyField;
        return error;
    }

    async getSIRETError(res: any): Promise<string> {
        let error = '';

        if (res instanceof RequestError) error = Wording.errors.SIRETValidity;
        return error;
    }
    getMailAddressError(value: string): string {
        let error = '';
        if (!isValidString(value)) error = Wording.errors.emptyField;
        else if (!isValidEmail(value)) {
            error = Wording.errors.mailAddressValidity;
        }
        return error;
    }

    getGroupError(value: string | SelectOption<any>): string {
        let error = '';
        if (!isEmptySelectOption(value)) {
            error = Wording.errors.groupValidity;
        }
        return error;
    }

    getPhoneNumberError(value: string): string {
        let error = '';
        if (!isValidString(value)) error = Wording.errors.emptyField;
        else if (!isValidPhone(value)) {
            error = Wording.errors.phoneNumberValidity;
        }
        return error;
    }
    getAddressError(value: string): string {
        let error = '';
        if (value.length <= 0) error = Wording.errors.addressValidity;
        return error;
    }
    handleLegalStatusSelection(status: SelectOption<ECompanyTypes>) {
        this.setState({
            legalStatusSelection: status,
        });
    }
    handleGroupSelection(group: SelectOption<any>) {
        this.setState({
            groupSelection: group,
            groupError: this.getGroupError(group),
        });
    }
    handlePostalCodeSelection(address: TAddress) {
        this.setState({ address });
    }

    handleBirthTownSelection(address: TAddress) {
        this.setState({
            birthTownTownName: address.townName || '',
            birthTownPostalCode: address.postalCode
                ? address.postalCode.toString()
                : '',
            birthTownCountry: 'FRANCE',
        });
    }
    handleBirthDatePick(date: Date | any) {
        if (date instanceof Date) {
            this.setState({
                birthDatePick: date,
                birthDateError: this.getBirthDateError(date),
            });
        }
    }
    handleBirthDatePickPro(date: Date | any) {
        if (date instanceof Date) {
            this.setState({
                birthDatePick: date,
                birthDateError: this.getBirthDateErrorPro(date),
            });
        }
    }
    handleIdenticalAddressToggle(toggled: boolean) {
        this.setState({
            identicalAddressToggleState: !!toggled,
        });
    }
    handleBusinessNameInput(e: ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        this.handleBusinessNameChange(value);
        this.handleBusinessNameError(value);
    }
    async handleSIRETInput(e: ChangeEvent<HTMLInputElement>) {
        const { searchSiret } = this.props;
        const { SIRETValue } = this.state;
        const value = formatSIRETString(e.target.value);
        let res: any;
        if (value.length === 14 && value !== SIRETValue) {
            res = await searchSiret(value);
            this.handleSIRETError(res);
        }

        this.handleSIRETChange(value, getAPEFromObject(res));
    }
    handleMailAddressInput(e: ChangeEvent<HTMLInputElement>) {
        const value = accents.remove(e.target.value);
        this.handleMailAddressChange(value);
        this.handleMailAddressError(value);
    }
    handlePhoneNumberInput(e: ChangeEvent<HTMLInputElement>) {
        const value = formatPhoneNumberString(e.target.value);
        this.handlePhoneNumberChange(value);
        this.handlePhoneNumberError(value);
    }
    handleAddressInput(value: string) {
        this.handleAddressChange(value);
        this.handleAddressError(value);
    }
    handleCoordsInput(values: {
        civility: ECivilities | null;
        firstName: string;
        lastName: string;
    }) {
        const firstName: string = formatFirstNameString(values.firstName);
        const lastName: string = formatLastNameString(values.lastName);
        this.handleFirstNameError(firstName);
        this.handleLastNameError(lastName);
        this.setState({
            civilStatusSelection: values.civility,
            firstNameValue: firstName,
            lastNameValue: lastName,
        });
    }
    handleBusinessNameChange(value: string) {
        this.setState({ businessNameValue: value });
    }
    handleSIRETChange(value: string, ape: string) {
        this.setState({ SIRETValue: value, APE: ape });
    }
    handleMailAddressChange(value: string) {
        this.setState({ mailAddressValue: value });
    }
    handlePhoneNumberChange(value: string) {
        this.setState({ phoneNumberValue: value });
    }
    handleAddressChange(value: string) {
        this.setState({ addressAddressValue: value });
    }
    handleFirstNameError(value: string) {
        this.setState({ firstNameError: this.getFirstNameError(value) });
    }

    handleLastNameError(value: string) {
        this.setState({ lastNameError: this.getLastNameError(value) });
    }

    handleBusinessNameError(value: string) {
        this.setState({ businessNameError: this.getBusinessNameError(value) });
    }
    async handleSIRETError(value: string, force?: boolean) {
        const SIRETError = await this.getSIRETError(value);
        this.setState({ SIRETError });
    }
    handleMailAddressError(value: string) {
        this.setState({ mailAddressError: this.getMailAddressError(value) });
    }
    handlePhoneNumberError(value: string) {
        this.setState({ phoneNumberError: this.getPhoneNumberError(value) });
    }
    handleAddressError(value: string) {
        this.setState({ addressAddressError: this.getAddressError(value) });
    }

    handleSetForeignData = (country: string) => {
        this.setState({
            birthTownCountry: country,
        });
    };

    handleBirthTownName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
            currentTarget: { value },
        } = e;

        this.setState({
            birthTownTownName: value,
        });
    };

    handleChangeCountryBirth = (obj: any) => {
        const { birthTownPostalCode, birthTownTownName } = this.state;
        const newCountry = obj.value.toUpperCase();
        let newPostalCOde = birthTownPostalCode;
        let newTownName = birthTownTownName;

        if (obj.value !== 'FRANCE') {
            newPostalCOde = '';
            newTownName = '';
        }

        this.setState({
            birthTownCountry: newCountry,
            birthTownTownName: newTownName,
            birthTownPostalCode: newPostalCOde,
        });
    };
    async hasValidInput(): Promise<boolean> {
        const { isIndividual } = this.props;
        const {
            firstNameValue,
            lastNameValue,
            birthDatePick,
            birthDatePickPro,
            businessNameValue,
            mailAddressValue,
            phoneNumberValue,
            addressAddressValue,
            address,
            identicalAddressToggleState: hasIdenticalAddress,
            SIRETError,
            groupSelection,
            birthTownCountry,
            birthTownPostalCode,
            birthTownTownName,
        } = this.state;

        const birthTownCheck =
            birthTownCountry !== '' && birthTownTownName !== '';

        let isValidForm =
            hasError(this.getFirstNameError(firstNameValue)) &&
            hasError(this.getLastNameError(lastNameValue)) &&
            hasError(this.getBirthDateError(birthDatePick)) &&
            hasError(this.getBirthDateErrorPro(birthDatePick)) &&
            hasError(this.getMailAddressError(mailAddressValue)) &&
            hasError(this.getPhoneNumberError(phoneNumberValue)) &&
            hasError(this.getGroupError(groupSelection)) &&
            birthTownCheck;

        if (!isIndividual) {
            isValidForm =
                isValidForm &&
                hasError(this.getBusinessNameError(businessNameValue)) &&
                !SIRETError;
        }
        if (!hasIdenticalAddress) {
            isValidForm =
                isValidForm &&
                hasError(this.getAddressError(addressAddressValue)); // &&
            this.validatePostalCode(address);
        }
        return isValidForm;
    }
    async validate() {
        const { isIndividual, onValidationStatusChange } = this.props;
        const {
            firstNameValue,
            lastNameValue,
            birthDatePick,
            businessNameValue,
            SIRETValue,
            mailAddressValue,
            phoneNumberValue,
            identicalAddressToggleState,
            addressAddressValue,
            address,
            civilStatusSelection,
            legalStatusSelection,
            APE,
            groupSelected,
            birthTownTownName,
            birthTownPostalCode,
            birthTownCountry,
        } = this.state;
        const isValid = await this.hasValidInput();
        let customer: any = {
            civility: civilStatusSelection,
            companyName: businessNameValue,
            email: mailAddressValue,
            firstName: firstNameValue,
            lastName: lastNameValue,
            phone: phoneNumberValue,
            birthTown: {
                ...defaultBirthTown,
                townName: birthTownTownName,
                country: birthTownCountry,
                postalCode: birthTownPostalCode,
            },
        };
        if (birthDatePick instanceof Date) {
            customer.birthdate = getFormattedDateToBackEnd(birthDatePick);
        }

        if (!isIndividual) {
            if (!!SIRETValue && !!APE) {
                customer = {
                    ...customer,
                    companyName: businessNameValue,
                    companyType: legalStatusSelection.value,
                    identificationNumber: SIRETValue,
                    activityCode: APE,
                };
            }
        }

        onValidationStatusChange(isValid, customer as TCustomerContact);
    }
    validatePostalCode(address: TAddress | null): boolean {
        return address !== null;
    }
    render() {
        const {
            initialParams,
            isIndividual,
            onIsIndividualToggle,
            groupList,
            handleGroupSelection,
        } = this.props;
        const {
            firstNameValue,
            lastNameValue,
            businessNameValue,
            SIRETValue,
            mailAddressValue,
            phoneNumberValue,
            identicalAddressToggleState,
            addressAddressValue,
            address,
            firstNameError,
            lastNameError,
            businessNameError,
            SIRETError,
            mailAddressError,
            phoneNumberError,
            addressAddressError,
            addressError,
            birthDatePick,
            birthDatePickPro,
            birthDateError,
            birthDateErrorPro,
            civilStatusSelection,
            legalStatusSelection,
            APE,
            groupSelected,
            groupError,
            birthTownCountry,
            birthTownPostalCode,
            birthTownTownName,
        } = this.state;
        const coordsInputsErrors = {
            firstName: firstNameError,
            lastName: lastNameError,
        };
        const coordsInputsValues = {
            civility: civilStatusSelection,
            firstName: firstNameValue,
            lastName: lastNameValue,
        };

        const countryIsFrance = birthTownCountry === 'FRANCE';

        return (
            <section className={bcn([baseClassName])}>
                <div className={bcn([baseClassName, 'select-group'])}>
                    <Group
                        value={this.state.groupSelection}
                        schemaProps={this.props.schemaProps}
                        options={_.map(
                            groupList,
                            (group: string, id: number) => ({
                                id,
                                label: group,
                                value: group,
                            }),
                        )}
                        handleChangeSelect={(group: SelectOption<string>) => {
                            this.handleGroupSelection(group);
                            handleGroupSelection(group);
                        }}
                        text={Wording.group}
                        defaultValue={this.state.defaultGroup}
                        error={groupError}
                    />
                </div>

                <div className={bcn([baseClassName, 'radio-buttons'])}>
                    <CustomRadio
                        text={Wording.business}
                        value={'pro'}
                        id={'pro'}
                        checked={!isIndividual}
                        handleCheck={() => {
                            onIsIndividualToggle(false);
                        }}
                    />
                    <CustomRadio
                        text={Wording.individual}
                        value={'ind'}
                        id={'ind'}
                        checked={isIndividual}
                        handleCheck={() => {
                            onIsIndividualToggle(true);
                        }}
                    />
                </div>
                <h2>{Wording.title}</h2>
                <div className={bcn([baseClassName, mainClassName])}>
                    <div
                        className={bcn([
                            baseClassName,
                            mainClassName,
                            `input-grid-${isIndividual ? 'ind' : 'pro'}`,
                        ])}
                    >
                        <CoordsInputs
                            errors={coordsInputsErrors}
                            onInputChange={this.handleCoordsInput}
                            values={coordsInputsValues}
                        />
                        <div
                            className={bcn([
                                baseClassName,
                                mainClassName,
                                `input-grid-${isIndividual ? 'ind' : 'pro'}`,
                                'date-picker',
                            ])}
                        >
                            <label>{birthDateText}</label>
                            {isIndividual ? (
                                <CustomDate
                                    error={birthDateError}
                                    onDateChange={this.handleBirthDatePick}
                                    value={birthDatePick}
                                />
                            ) : (
                                <CustomDate
                                    error={birthDateError}
                                    onDateChange={this.handleBirthDatePickPro}
                                    value={birthDatePickPro}
                                />
                            )}
                        </div>

                        <div
                            className={bcn([
                                baseClassName,
                                mainClassName,
                                `input-grid-${isIndividual ? 'ind' : 'pro'}`,
                                'address-foreign',
                            ])}
                        >
                            <CustomSelect
                                options={countries.map(vl => ({
                                    id: vl.code,
                                    label: `${vl.name}`,
                                    value: vl.name,
                                }))}
                                handleChangeSelect={
                                    this.handleChangeCountryBirth
                                }
                                text={Wording.inputs.countryBirth}
                                placeholder=" "
                                value={{
                                    value: birthTownCountry,
                                    label: birthTownCountry,
                                }}
                            />
                        </div>

                        {countryIsFrance && (
                            <div
                                className={bcn([
                                    baseClassName,
                                    mainClassName,
                                    `input-grid-${
                                        isIndividual ? 'ind' : 'pro'
                                    }`,
                                    'postal-code',
                                ])}
                            >
                                <PostalCodeSelect
                                    onSelection={(birthTown: TAddress) => {
                                        this.handleBirthTownSelection(
                                            birthTown,
                                        );
                                    }}
                                    initialAddress={
                                        birthTownPostalCode !== ''
                                            ? {
                                                  postalCode: Number(
                                                      birthTownPostalCode,
                                                  ),
                                                  number: '',
                                                  street: '',
                                                  townName: birthTownTownName,
                                              }
                                            : undefined
                                    }
                                    text="Code postal"
                                />
                            </div>
                        )}

                        <CustomInput
                            text={Wording.inputs.birthPlace}
                            isLocked={countryIsFrance}
                            onInputChange={this.handleBirthTownName}
                            value={birthTownTownName}
                        />

                        {!isIndividual && (
                            <CustomInput
                                error={businessNameError}
                                onInputChange={this.handleBusinessNameInput}
                                text={businessNameText}
                                value={businessNameValue}
                                isLocked={false}
                            />
                        )}
                        {!isIndividual && (
                            <CustomSelect
                                id="legal_status"
                                defaultValue={legalStatusSelection.label}
                                options={legalStatuses}
                                handleChangeSelect={(
                                    status: SelectOption<ECompanyTypes>,
                                ) => {
                                    this.handleLegalStatusSelection(status);
                                }}
                                value={legalStatusSelection}
                                text={Wording.inputs.legalStatus}
                            />
                        )}
                        {!isIndividual && (
                            <CustomInput
                                error={SIRETError}
                                onInputChange={this.handleSIRETInput}
                                text={SIRETText}
                                value={SIRETValue}
                            />
                        )}
                        {!isIndividual && (
                            <CustomInput
                                isLocked={true}
                                text={Wording.ape}
                                value={APE}
                            />
                        )}
                        <CustomInput
                            error={phoneNumberError}
                            onInputChange={this.handlePhoneNumberInput}
                            text={phoneNumberText}
                            value={phoneNumberValue}
                        />
                        <CustomInput
                            error={mailAddressError}
                            onInputChange={this.handleMailAddressInput}
                            text={mailAddressText}
                            value={mailAddressValue}
                        />
                    </div>
                </div>
                <AddressSelect
                    addressError={addressAddressError}
                    initialAddress={
                        !!initialParams
                            ? initialParams.address || undefined
                            : undefined
                    }
                    onAddressInputChange={this.handleAddressInput}
                    onIdenticalAddressToggle={this.handleIdenticalAddressToggle}
                    onPostalCodeSelection={this.handlePostalCodeSelection}
                    text={Wording.identicalAddress.label}
                />
            </section>
        );
    }
}

export default ClientDataSection;
