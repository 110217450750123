import React, { CSSProperties } from 'react';
import { Subtract } from 'utility-types';
import { Modal } from '../components';

type PopupItem = {
    state: number;
    title: string;
    description: string;
    // customDescription?: string;
};

export type ExternalProps = {
    updateResponse: {
        code: string;
        message: string;
    };
    modalMessage: {
        success: PopupItem;
        failure: PopupItem;
        request: PopupItem;
    };
    customDescription?: string;
    showLogo?: boolean;
    descriptionStyle?: CSSProperties;
    handleValidate(section: string): void;
    handleReset(): void;
};

type Options = {
    debug?: boolean;
};

export type InjectedProps = {
    handleShowModal(section: string): void;
};

type State = {
    showModal: boolean;
    section: string;
};

const withModal = ({ debug = false }: Options = {}) => <
    OwnProps extends InjectedProps
>(
    WrappedComponent: React.ComponentType<OwnProps>,
) => {
    type P = OwnProps & ExternalProps;
    type HocProps = Subtract<P, InjectedProps>;

    return class extends React.Component<HocProps, State> {
        state = {
            showModal: false,
            section: '',
        };

        handleConfirmValidate = () => {
            const { section } = this.state;
            const { handleValidate } = this.props;
            handleValidate(section);
            this.setState({ section: '' });
        };

        handleState = (): PopupItem => {
            const { updateResponse, modalMessage } = this.props;
            if (updateResponse === undefined) return modalMessage.failure;
            const code = Number(updateResponse.code);
            switch (code) {
                case 0:
                    return modalMessage.request;
                case 200:
                    return modalMessage.success;
                default:
                    return modalMessage.failure;
            }
        };

        handleShowModal = (section: string) => {
            const { showModal } = this.state;
            this.setState({ showModal: !showModal, section });
        };

        handleHideModal = () => {
            this.setState({ showModal: false });
            const { handleReset } = this.props;
            handleReset();
        };

        render() {
            const { showModal } = this.state;
            const { title, description, state } = this.handleState();
            const {
                customDescription,
                descriptionStyle,
                showLogo,
            } = this.props;
            return (
                <React.Fragment>
                    <Modal
                        show={showModal}
                        title={title}
                        description={description}
                        customDescription={undefined}
                        state={state}
                        handleCancel={this.handleHideModal}
                        handleValidate={this.handleConfirmValidate}
                        descriptionStyle={descriptionStyle}
                        showLogo={showLogo}
                    />
                    <WrappedComponent
                        {...(this.props as OwnProps)}
                        handleShowModal={this.handleShowModal}
                    />
                </React.Fragment>
            );
        }
    };
};

export default withModal;
