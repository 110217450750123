import React, { ChangeEvent, Component } from 'react';
import _ from 'lodash';
import WordingConstant from '../../../../../utils/wording.json';
import {
    CustomButton,
    CustomError,
    CustomInput,
    CustomSelect,
} from '../../../../../components';
import {
    buildClassName as bcn,
    formatSiteString,
    parseQueryParams,
} from '../../../../../utils/helpers';
import { toIsc } from '../../../../../utils/text';
import { ESubscriptionTypes } from '../../../../../utils/network/types';
import { SelectOption } from 'Types';
import {
    RequestError,
    NoMatchingPackagesError,
    NoPackageListError,
    ExternalError,
} from '../../../../../utils/network/errors';
import Data from './Data';

interface IProps {
    didValidateSurvey: boolean;
    initialParams?: Data;
    onMissingSiteId: () => void;
    onSiteOptionSelection: (type: ESubscriptionTypes) => void;
    onValidationStatusChange: (validated: boolean) => void;
    submitSiteData: (
        electricity: string,
        gas: string,
    ) => Promise<boolean | RequestError>;
    PDLRef: React.MutableRefObject<string | null>;
    PCERef: React.MutableRefObject<string | null>;
    elecAdress?: string;
    gazAdress?: string;
}

interface IState {
    PCEError: string;
    PCEValue: string;
    PDLError: string;
    PDLValue: string;
    requestError: string | null;
    siteOptionSelection: SelectOption<ESubscriptionTypes> | null;
}

const Wording = WordingConstant.AdminSubscriptionContainer.Site;
const baseClassName = 'site-section';
const mainClassName = 'main';
const compoundOption = toIsc(`${Wording.electricity} & ${Wording.gas}`);
const siteOptions: Array<SelectOption<ESubscriptionTypes>> = [
    {
        id: Wording.electricity,
        label: Wording.electricity,
        value: ESubscriptionTypes.ELECTRICITY,
    },
    {
        id: compoundOption,
        label: compoundOption,
        value: ESubscriptionTypes.ELECTRICITY_AND_GAS,
    },
    {
        id: Wording.gas,
        label: Wording.gas,
        value: ESubscriptionTypes.GAS,
    },
];

class SiteSection extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            PCEError: '',
            PCEValue: '',
            PDLError: '',
            PDLValue: '',
            requestError: null,
            siteOptionSelection: null,
        };
        this.handleMissingSiteIDIfNeeded = this.handleMissingSiteIDIfNeeded.bind(
            this,
        );
        this.handlePCEInput = this.handlePCEInput.bind(this);
        this.handlePDLInput = this.handlePDLInput.bind(this);
        this.handleSiteOptionSelection = this.handleSiteOptionSelection.bind(
            this,
        );
        this.handleValidationClick = this.handleValidationClick.bind(this);
        this.submitData = this.submitData.bind(this);
    }
    componentDidMount() {
        this.init();

        this.initWithUrl();
    }
    init() {
        const { initialParams } = this.props;
        if (!!initialParams) {
            const siteOptionSelection: SelectOption<ESubscriptionTypes> | null =
                _.find(
                    siteOptions,
                    (option: SelectOption<ESubscriptionTypes>) => {
                        return (
                            option.value === initialParams.siteOptionSelection
                        );
                    },
                ) || null;
            this.setState({
                PCEValue: initialParams.PCEValue || '',
                PDLValue: initialParams.PDLValue || '',
                siteOptionSelection,
            });
        }
    }

    initWithUrl() {
        const { onSiteOptionSelection } = this.props;

        const params = parseQueryParams(
            document.location,
            window.location.host,
            window.location.protocol,
        );

        const energies = params.get('energyTypes[]');

        if (!!energies) {
            const manageSelection = (() => {
                const spltEng = energies && energies.toString().split('|');

                if (spltEng && spltEng.length === 2) {
                    return ESubscriptionTypes.ELECTRICITY_AND_GAS;
                }

                return spltEng && (spltEng[0] as string) === 'EL'
                    ? ESubscriptionTypes.ELECTRICITY
                    : ESubscriptionTypes.GAS;
            })();

            this.setState({
                siteOptionSelection:
                    _.find(
                        siteOptions,
                        (option: SelectOption<ESubscriptionTypes>) => {
                            return option.value === manageSelection;
                        },
                    ) || null,
            });

            if (manageSelection) onSiteOptionSelection(manageSelection);
        }
    }
    checkInputValidity(
        PCE: string,
        PDL: string,
        type: ESubscriptionTypes | null,
    ): boolean {
        let hasValidInput: boolean = false;
        if (type === ESubscriptionTypes.ELECTRICITY_AND_GAS) {
            const hasValidPCE = this.checkPCEValidity(PCE);
            const hasValidPDL = this.checkPDLValidity(PDL);
            hasValidInput = hasValidPCE && hasValidPDL;
        } else if (type === ESubscriptionTypes.ELECTRICITY) {
            hasValidInput = this.checkPDLValidity(PDL);
        } else if (type === ESubscriptionTypes.GAS) {
            hasValidInput = this.checkPCEValidity(PCE);
        }
        return hasValidInput;
    }
    checkPCEValidity(PCE: string): boolean {
        const isValid = this.validatePCE(PCE);
        if (!isValid) this.setState({ PCEError: Wording.PCEError });
        return isValid;
    }
    checkPDLValidity(PDL: string): boolean {
        const isValid = this.validatePDL(PDL);
        if (!isValid) this.setState({ PDLError: Wording.PDLError });
        return isValid;
    }
    handleMissingSiteIDIfNeeded(force: boolean = false) {
        const { onMissingSiteId } = this.props;
        const { PCEValue, PDLValue, siteOptionSelection } = this.state;
        if (siteOptionSelection === null) return;
        let clickEnabled = true;
        if (
            siteOptionSelection.value === ESubscriptionTypes.ELECTRICITY_AND_GAS
        ) {
            clickEnabled =
                clickEnabled && PDLValue.length === 0 && PCEValue.length === 0;
        } else if (
            siteOptionSelection.value === ESubscriptionTypes.ELECTRICITY
        ) {
            clickEnabled = clickEnabled && PDLValue.length === 0;
        } else if (siteOptionSelection.value === ESubscriptionTypes.GAS) {
            clickEnabled = clickEnabled && PCEValue.length === 0;
        }
        if (force || clickEnabled) onMissingSiteId();
    }
    handlePCEInput(e: ChangeEvent<HTMLInputElement>) {
        let value = _.get(e, 'target.value', '');
        value = formatSiteString(value);
        this.props.PCERef.current = value;
        this.setState({
            PCEError: '',
            PCEValue: value,
            requestError: '',
        });
    }
    handlePDLInput(e: ChangeEvent<HTMLInputElement>) {
        let value = _.get(e, 'target.value', '');
        value = formatSiteString(value);
        this.props.PDLRef.current = value;
        this.setState({
            PDLError: '',
            PDLValue: value,
            requestError: '',
        });
    }
    handleSiteOptionSelection(type: SelectOption<ESubscriptionTypes>) {
        const { onSiteOptionSelection } = this.props;
        onSiteOptionSelection(type.value);
        this.setState({
            siteOptionSelection: type,
            requestError: '',
        });
    }
    handleValidationClick() {
        const { PCEValue, PDLValue, siteOptionSelection } = this.state;
        const type: ESubscriptionTypes | null = _.get(
            siteOptionSelection,
            'value',
            null,
        );
        const hasValidInput = this.checkInputValidity(PCEValue, PDLValue, type);
        this.submitData(hasValidInput);
    }
    shouldShow(input: ESubscriptionTypes): boolean {
        const { siteOptionSelection } = this.state;
        const value = _.get(siteOptionSelection, 'value');
        return (
            input === value || value === ESubscriptionTypes.ELECTRICITY_AND_GAS
        );
    }
    async submitData(hasValidInput: boolean) {
        const { onValidationStatusChange, submitSiteData } = this.props;
        const { PCEValue, PDLValue, siteOptionSelection } = this.state;
        if (!hasValidInput) {
            onValidationStatusChange(false);
            return;
        }
        if (!!siteOptionSelection) {
            let res: boolean | RequestError;
            if (this.state.siteOptionSelection) {
                if (
                    this.state.siteOptionSelection.value ===
                    ESubscriptionTypes.ELECTRICITY
                ) {
                    res = await submitSiteData(PDLValue, '');
                } else if (
                    this.state.siteOptionSelection.value ===
                    ESubscriptionTypes.GAS
                ) {
                    res = await submitSiteData('', PCEValue);
                } else {
                    res = await submitSiteData(PDLValue, PCEValue);
                }
            } else {
                res = await submitSiteData(PDLValue, PCEValue);
            }

            let validated = false;
            if (res instanceof RequestError) {
                let message = WordingConstant.Errors.default;
                if (res instanceof ExternalError) {
                    message = res.message;
                }
                if (
                    res instanceof NoPackageListError ||
                    res instanceof NoMatchingPackagesError
                ) {
                    message = res.message;
                    this.handleMissingSiteIDIfNeeded(true);
                }
                this.setState({ requestError: message });
            } else {
                validated = true;
                this.setState({ requestError: null });
                this.handleMissingSiteIDIfNeeded();
            }
            onValidationStatusChange(validated);
        }
    }
    validateSiteId(id: string) {
        return id.replace(/\s/g, '').length === 14;
    }
    validatePCE(PCE: string): boolean {
        return this.validateSiteId(PCE);
    }
    validatePDL(PDL: string): boolean {
        return this.validateSiteId(PDL);
    }
    render() {
        const { didValidateSurvey, elecAdress, gazAdress } = this.props;
        const {
            PCEError,
            PCEValue,
            PDLError,
            PDLValue,
            requestError,
            siteOptionSelection,
        } = this.state;

        return (
            <section className={baseClassName}>
                <h2>{Wording.title}</h2>
                <div className={bcn([baseClassName, mainClassName])}>
                    <CustomSelect
                        handleChangeSelect={this.handleSiteOptionSelection}
                        options={siteOptions}
                        text={`${Wording.offerPick}${toIsc(' :')}`}
                        value={siteOptionSelection}
                    />
                    <div
                        className={bcn([baseClassName, mainClassName, 'site'])}
                    >
                        {this.shouldShow(ESubscriptionTypes.ELECTRICITY) && (
                            <CustomInput
                                error={PDLError}
                                onInputChange={this.handlePDLInput}
                                text={`${Wording.PDLPrompt}${toIsc(' :')}`}
                                value={PDLValue}
                            />
                        )}
                        <span
                            style={{
                                paddingLeft: '35%',
                                fontWeight: 'bold',
                            }}
                        >
                            {elecAdress}
                        </span>

                        {this.shouldShow(ESubscriptionTypes.GAS) && (
                            <CustomInput
                                style={
                                    gazAdress
                                        ? { marginTop: '20px' }
                                        : undefined
                                }
                                error={PCEError}
                                onInputChange={this.handlePCEInput}
                                text={`${Wording.PCEPrompt}${toIsc(' :')}`}
                                value={PCEValue}
                            />
                        )}
                        <span
                            style={{ paddingLeft: '35%', fontWeight: 'bold' }}
                        >
                            {gazAdress}
                        </span>
                    </div>
                    {!!requestError && !didValidateSurvey && (
                        <CustomError
                            text={requestError}
                            title={Wording.error}
                        />
                    )}
                    <CustomButton
                        color={'orange'}
                        heavy={true}
                        onClick={this.handleValidationClick}
                        title={Wording.validate}
                    />
                    <div
                        className={bcn([
                            baseClassName,
                            mainClassName,
                            didValidateSurvey
                                ? 'missing--highlighted'
                                : 'missing',
                        ])}
                    >
                        <span
                            onClick={() => this.handleMissingSiteIDIfNeeded()}
                        >
                            {Wording.missingSiteID}
                        </span>
                        <p>{Wording.terms}</p>
                    </div>
                </div>
            </section>
        );
    }
}

export default SiteSection;
