import React, { Component } from 'react';
import _, { isEmpty } from 'lodash';
import { SelectOption } from 'Types';
import WordingConstant from '../../../../../utils/wording.json';
import classnames from 'classnames';
import {
    buildClassName as bcn,
    prepareFrequenciesToDisplay,
} from '../../../../../utils/helpers';
import { toColonHeading as tch } from '../../../../../utils/text';
import {
    CustomButton,
    CustomModal,
    CustomOverlay,
    CustomSelect,
    ToggleIndicator,
} from '../../../../../components';
import SeasonalOffersModal from '../../modals/SeasonalOffersModal';
import {
    EAdditionalRateTypes,
    EEnergyTypes,
    EInstallmentFrequencies,
    TContract,
    TPackage,
    TProduct,
    TAdditionalRate,
    TAuthorizedPackage,
    ETimeframes,
    TPrepaymentFrequencies,
    TEstimate,
} from '../../../../../utils/network/types';
import { isCyclicalProduct } from '../../../../../utils/network/helpers';
import Options from './Options';
import Data from './Data';
import ClientConsumption from './ClientConsumption';

type GaugeProps = {
    initial: number;
    ranges: number[][];
    maxValue: number;
};

function filterOptions(
    opts: TAdditionalRate[] = [],
    optionFilter: string | EAdditionalRateTypes = 'OPTION',
): TAdditionalRate[] {
    const filteredOpts = opts.filter((opt: TAdditionalRate): boolean => {
        return opt.type === `${optionFilter}`;
    });
    return filteredOpts;
}

interface IProps {
    contracts: TContract[] | null;
    frequencies: TPrepaymentFrequencies[] | null;
    electricityEnabled: boolean;
    gasEnabled: boolean;
    initialParams?: Data;
    onProductSelection: (
        energy: EEnergyTypes,
        product: TProduct | null,
    ) => void;
    onValidate: (selection: {
        electricityFrequency?: EInstallmentFrequencies;
        electricityPackage?: TPackage;
        electricitySeasonalPackage?: TAuthorizedPackage | null;
        electricityProduct?: TProduct;
        gasFrequency?: EInstallmentFrequencies;
        gasPackage?: TPackage;
        gasProduct?: TProduct;
        gasSeasonalPackage?: TAuthorizedPackage | null;
    }) => void;
    packages: TPackage[] | null;
    products: TProduct[] | null;
    isPackageValid: boolean;
    deliveryPointPackages: any;

    handleSetGaugeProps: (gaugeProps: {
        electricityGaugeProps: GaugeProps | null;
        gasGaugeProps: GaugeProps | null;
    }) => void;

    handleEstimatesPackagesByClient: (
        estimates: TEstimate[],
        energies: EEnergyTypes[],
    ) => void;
}

interface IState {
    authorizedElectricityPackages: TAuthorizedPackage[];
    authorizedElectricityPackageSelection: TAuthorizedPackage | null;
    authorizedGasPackages: TAuthorizedPackage[];
    authorizedGasPackageSelection: TAuthorizedPackage | null;
    electricityFrequencies: Array<SelectOption<EInstallmentFrequencies>>;
    electricityFrequencySelection: SelectOption<EInstallmentFrequencies> | null;
    electricityOptions: TAdditionalRate[];
    electricityOptionSelection: boolean[];
    electricityPlans: Array<SelectOption<TPackage>>;
    electricityPlanSelection: SelectOption<TPackage> | null;
    electricityProducts: Array<SelectOption<TProduct>>;
    electricityProductSelection: SelectOption<TProduct> | null;
    gasFrequencies: Array<SelectOption<EInstallmentFrequencies>>;
    gasFrequencySelection: SelectOption<EInstallmentFrequencies> | null;
    gasOptions: TAdditionalRate[];
    gasOptionSelection: boolean[];
    gasPlans: Array<SelectOption<TPackage>>;
    gasPlanSelection: SelectOption<TPackage> | null;
    gasProducts: Array<SelectOption<TProduct>>;
    gasProductSelection: SelectOption<TProduct> | null;
    initialElectricityOptionSelection: TAdditionalRate[] | null;
    initialGasOptionSelection: TAdditionalRate[] | null;
    shouldShowGauge: boolean;
    showConsumptionModal: boolean;
    initialElectricityRateOptions: TAdditionalRate[];
    initialGasRateOptions: TAdditionalRate[];
}

const Wording = WordingConstant.AdminSubscriptionContainer.Offer;
const baseClassName = 'offer-section';
const selectGridClassName = 'select-grid';

const mapPackagePrepaymentFrequencies = (
    frequencies: TPrepaymentFrequencies[] | null,
): Array<SelectOption<EInstallmentFrequencies>> => {
    if (frequencies === null) {
        return [];
    }

    return _.map(
        frequencies,
        ({ frequency, currency, fee }: TPrepaymentFrequencies, i) => {
            let label = '';
            const displayFee = fee.includes(' +') ? `${fee}${' '}` : fee;

            switch (frequency) {
                case EInstallmentFrequencies.MONTHLY:
                    label = `${Wording.frequencies.monthly}: ${displayFee}${currency}`;
                    break;
                case EInstallmentFrequencies.BIMONTHLY:
                    label = `${Wording.frequencies.bimonthly}: ${displayFee}${currency}`;
                    break;
                case EInstallmentFrequencies.FOURMONTHLY:
                    label = `${Wording.frequencies.fourmonthly}: ${displayFee}${currency}`;
                    break;
                case EInstallmentFrequencies.QUARTERLY:
                    label = `${Wording.frequencies.quarterly}: ${displayFee}${currency}`;
                    break;
            }
            return { id: `${i}`, label, value: frequency };
        },
    );
};

const initialState: IState = {
    initialElectricityRateOptions: [],
    initialGasRateOptions: [],
    authorizedElectricityPackages: [],
    authorizedElectricityPackageSelection: null,
    authorizedGasPackages: [],
    authorizedGasPackageSelection: null,
    electricityFrequencies: [],
    electricityFrequencySelection: null,
    electricityOptions: [],
    electricityOptionSelection: [],
    electricityPlans: [],
    electricityPlanSelection: null,
    electricityProducts: [],
    electricityProductSelection: null,
    gasFrequencies: [],
    gasFrequencySelection: null,
    gasOptions: [],
    gasOptionSelection: [],
    gasPlans: [],
    gasPlanSelection: null,
    gasProducts: [],
    gasProductSelection: null,
    initialElectricityOptionSelection: null,
    initialGasOptionSelection: null,
    shouldShowGauge: false,
    showConsumptionModal: false,
};

export function sortAuthorizedPackages(
    packages: TAuthorizedPackage[],
): TAuthorizedPackage[] {
    const orderedTimeframePackages: TAuthorizedPackage[] = _.reduce<
        TAuthorizedPackage,
        TAuthorizedPackage[]
    >(
        packages,
        (result: TAuthorizedPackage[], value: TAuthorizedPackage) => {
            const summerIndex =
                value[0].timeframe === ETimeframes.SUMMER
                    ? 0
                    : value[1].timeframe === ETimeframes.SUMMER
                    ? 1
                    : -1;
            const winterIndex =
                value[0].timeframe === ETimeframes.WINTER
                    ? 0
                    : value[1].timeframe === ETimeframes.WINTER
                    ? 1
                    : -1;
            if (summerIndex >= 0 && winterIndex >= 0) {
                result.push([value[summerIndex], value[winterIndex]]);
            }
            return result;
        },
        [],
    );
    const [left, right]: [
        TAuthorizedPackage[],
        TAuthorizedPackage[],
    ] = _.partition(orderedTimeframePackages, (pkg: TAuthorizedPackage) => {
        return Number(pkg[0].amount) < Number(pkg[1].amount);
    });
    return [
        ...left.sort((a: TAuthorizedPackage, b: TAuthorizedPackage): number => {
            return Number(a[1].amount) > Number(b[1].amount) ? -1 : 1;
        }),
        ...right.sort(
            (a: TAuthorizedPackage, b: TAuthorizedPackage): number => {
                return Number(a[0].amount) < Number(b[0].amount) ? -1 : 1;
            },
        ),
    ];
}

function matchFrequency(
    rate: TAdditionalRate,
    freq: EInstallmentFrequencies,
): boolean {
    let match = false;
    if (!_.includes(rate.code, 'PP_FREQ')) {
        return true;
    }
    switch (freq) {
        case EInstallmentFrequencies.MONTHLY:
            match = rate.code ? rate.code.includes('PP_FREQ_1') : false; // BECAUSE PP_FREQ_1_VAR || PP_FREQ_1_FIX
            break;
        case EInstallmentFrequencies.BIMONTHLY:
            match = rate.code ? rate.code.includes('PP_FREQ_2') : false;
            break;
        case EInstallmentFrequencies.FOURMONTHLY:
            match = rate.code ? rate.code.includes('PP_FREQ_4') : false;
            break;
        case EInstallmentFrequencies.QUARTERLY:
            match = rate.code ? rate.code.includes('PP_FREQ_3') : false;
            break;
    }
    return match;
}

class OfferSection extends Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = { ...initialState };
        this.handleSeasonalOfferModalDismiss = this.handleSeasonalOfferModalDismiss.bind(
            this,
        );
        this.handleElectricityFrequencySelection = this.handleElectricityFrequencySelection.bind(
            this,
        );
        this.handleElectricityPlanSelection = this.handleElectricityPlanSelection.bind(
            this,
        );
        this.handleElectricityProductSelection = this.handleElectricityProductSelection.bind(
            this,
        );
        this.handleGasFrequencySelection = this.handleGasFrequencySelection.bind(
            this,
        );
        this.handleGasPlanSelection = this.handleGasPlanSelection.bind(this);
        this.handleGasProductSelection = this.handleGasProductSelection.bind(
            this,
        );
        this.handleOptionSelection = this.handleOptionSelection.bind(this);
        this.handleSeasonalOffersModalToggle = this.handleSeasonalOffersModalToggle.bind(
            this,
        );
        this.handleValidationClick = this.handleValidationClick.bind(this);
        this.validateSeasonalOffersModal = this.validateSeasonalOffersModal.bind(
            this,
        );
    }
    componentDidMount() {
        this.init();
    }
    componentDidUpdate(prevProps: IProps) {
        const {
            contracts: prevContracts,
            electricityEnabled: prevElectricityEnabled,
            gasEnabled: prevGasEnabled,
            packages: prevPackages,
            products: prevProducts,
            frequencies: prevFrequencies,
            isPackageValid: prevIsPackageValid,
        } = prevProps;

        const {
            contracts,
            electricityEnabled,
            gasEnabled,
            packages,
            products,
            frequencies,
            isPackageValid,
            deliveryPointPackages,
        } = this.props;

        const shouldUpdate =
            prevContracts !== contracts ||
            JSON.stringify(prevFrequencies) !== JSON.stringify(frequencies) ||
            JSON.stringify(prevPackages) !== JSON.stringify(packages) ||
            JSON.stringify(prevProducts) !== JSON.stringify(products) ||
            prevGasEnabled !== gasEnabled ||
            prevElectricityEnabled !== electricityEnabled;
        if (shouldUpdate) {
            this.refresh(contracts, packages, products, frequencies);
        }

        if (prevIsPackageValid !== isPackageValid && !isPackageValid) {
            const plans: Array<SelectOption<TPackage>> = _.map(
                deliveryPointPackages,
                (plan: TPackage, i: number) => ({
                    id: `${i}`,
                    label: plan.id,
                    value: plan,
                }),
            );
            const electricityPlans: Array<SelectOption<
                TPackage
            >> = plans.filter(
                (plan: SelectOption<TPackage>) =>
                    plan.value.energy === EEnergyTypes.ELECTRICTY,
            );
            const gasPlans: Array<SelectOption<TPackage>> = plans.filter(
                (plan: SelectOption<TPackage>) =>
                    plan.value.energy === EEnergyTypes.GAS,
            );

            const recommendedElectricityPlans: Array<SelectOption<
                TPackage
            >> = _.map(
                electricityPlans,
                (plan: SelectOption<TPackage>, i: number) => ({
                    id: `${i}`,
                    label: `${plan.label}${
                        plan.value.recommended ? ' (Recommandé)' : ''
                    }`,
                    value: plan.value,
                }),
            );

            const recommendedGasPlans: Array<SelectOption<TPackage>> = _.map(
                gasPlans,
                (plan: SelectOption<TPackage>, i: number) => ({
                    id: `${i}`,
                    label: `${plan.label}${
                        plan.value.recommended ? ' (Recommandé)' : ''
                    }`,
                    value: plan.value,
                }),
            );

            this.setState({
                electricityPlans: recommendedElectricityPlans,
                electricityPlanSelection: null,
                gasPlans: recommendedGasPlans,
                gasPlanSelection: null,
            });
        }
    }
    handleSeasonalOfferModalDismiss() {
        this.setState({ shouldShowGauge: false });
    }

    init() {
        const { initialParams } = this.props;

        if (!!initialParams) {
            const authorizedElectricityPackages: TAuthorizedPackage[] = sortAuthorizedPackages(
                initialParams.authorizedElectricityPackages,
            );

            const authorizedElectricityPackageSelection: TAuthorizedPackage | null =
                initialParams.authorizedElectricityPackageSelection;
            const authorizedGasPackages: TAuthorizedPackage[] = sortAuthorizedPackages(
                initialParams.authorizedGasPackages,
            );
            const authorizedGasPackageSelection: TAuthorizedPackage | null =
                initialParams.authorizedGasPackageSelection;

            const mappedFrequencies = mapPackagePrepaymentFrequencies(
                initialParams.frequencies,
            );

            const electricityFrequencies: Array<SelectOption<
                EInstallmentFrequencies
            >> = mappedFrequencies;
            const electricityPlans: Array<SelectOption<TPackage>> = _.map(
                initialParams.electricityPlans,
                (plan: TPackage, i: number) => ({
                    id: `${i}`,
                    label: plan.id,
                    value: plan,
                }),
            );
            const gasPlans: Array<SelectOption<TPackage>> = _.map(
                initialParams.gasPlans,
                (plan: TPackage, i: number) => ({
                    id: `${i}`,
                    label: plan.id,
                    value: plan,
                }),
            );
            const electricityProducts: Array<SelectOption<TProduct>> = _.map(
                initialParams.electricityProducts,
                (prod: TProduct, i: number) => ({
                    id: `${i}`,
                    label: prod.label || '',
                    value: prod,
                }),
            );
            const gasProducts: Array<SelectOption<TProduct>> = _.map(
                initialParams.gasProducts,
                (prod: TProduct, i: number) => ({
                    id: `${i}`,
                    label: prod.label || '',
                    value: prod,
                }),
            );
            const electricityPlanSelection: SelectOption<TPackage> | null =
                _.find(electricityPlans, (opt: SelectOption<TPackage>) => {
                    return (
                        !!opt &&
                        !!initialParams.electricityPlanSelection &&
                        opt.value.id ===
                            initialParams.electricityPlanSelection.id
                    );
                }) || null;
            const electricityProductSelection: SelectOption<TProduct> | null =
                _.find(electricityProducts, (opt: SelectOption<TProduct>) => {
                    return (
                        !!opt &&
                        !!initialParams.electricityProductSelection &&
                        opt.value.productCode ===
                            initialParams.electricityProductSelection
                                .productCode
                    );
                }) || null;
            const gasPlanSelection: SelectOption<TPackage> | null =
                _.find(gasPlans, (opt: SelectOption<TPackage>) => {
                    return (
                        !!opt &&
                        !!initialParams.gasPlanSelection &&
                        opt.value.id === initialParams.gasPlanSelection.id
                    );
                }) || null;
            const gasProductSelection: SelectOption<TProduct> | null =
                _.find(gasProducts, (opt: SelectOption<TProduct>) => {
                    return (
                        !!opt &&
                        !!initialParams.gasProductSelection &&
                        opt.value.productCode ===
                            initialParams.gasProductSelection.productCode
                    );
                }) || null;
            let electricityFrequencySelection: SelectOption<
                EInstallmentFrequencies
            > | null = null;

            if (!!electricityProductSelection) {
                electricityFrequencySelection =
                    _.find(
                        electricityFrequencies,
                        (freq: SelectOption<EInstallmentFrequencies>) => {
                            return (
                                !!freq &&
                                initialParams.electricityFrequencySelection ===
                                    freq.value
                            );
                        },
                    ) || electricityFrequencies[0];
            }
            const gasFrequencies: Array<SelectOption<
                EInstallmentFrequencies
            >> = mappedFrequencies;
            let gasFrequencySelection: SelectOption<
                EInstallmentFrequencies
            > | null = null;
            if (!!gasProductSelection) {
                gasFrequencySelection =
                    _.find(
                        gasFrequencies,
                        (freq: SelectOption<EInstallmentFrequencies>) => {
                            return (
                                !!freq &&
                                initialParams.gasFrequencySelection ===
                                    freq.value
                            );
                        },
                    ) || gasFrequencies[0];
            }
            const electricityOptions: TAdditionalRate[] = filterOptions([
                ...initialParams.electricityOptions,
            ]);
            const gasOptions: TAdditionalRate[] = filterOptions([
                ...initialParams.gasOptions,
            ]);
            const electricityOptionSelection: boolean[] = _.map(
                electricityOptions,
                (rate: TAdditionalRate) => {
                    return !!_.find(
                        initialParams.electricitySelectedOptions,
                        rate,
                    );
                },
            );
            const gasOptionSelection: boolean[] = _.map(
                gasOptions,
                (rate: TAdditionalRate) => {
                    return !!_.find(initialParams.gasSelectedOptions, rate);
                },
            );
            this.setState({
                authorizedElectricityPackages,
                authorizedElectricityPackageSelection,
                authorizedGasPackages,
                authorizedGasPackageSelection,
                electricityFrequencies,
                electricityFrequencySelection,
                electricityOptions,
                electricityOptionSelection,
                electricityPlans,
                electricityPlanSelection,
                electricityProducts,
                electricityProductSelection,
                gasFrequencies,
                gasFrequencySelection,
                gasOptions,
                gasOptionSelection,
                gasPlans,
                gasPlanSelection,
                gasProducts,
                gasProductSelection,
                initialElectricityRateOptions: electricityProductSelection
                    ? electricityProductSelection.value.additionalRates || []
                    : [],
                initialGasRateOptions: gasProductSelection
                    ? gasProductSelection.value.additionalRates || []
                    : [],
            });
        }
    }
    handleElectricityFrequencySelection(
        opt: SelectOption<EInstallmentFrequencies>,
    ) {
        this.setState({ electricityFrequencySelection: opt });
    }
    handleElectricityPlanSelection(
        electricityPlanSelection: SelectOption<TPackage>,
    ) {
        let authorizedPackages: TAuthorizedPackage[] = [];
        if (
            !!electricityPlanSelection &&
            !!electricityPlanSelection.value.authorizedPackages &&
            electricityPlanSelection.value.authorizedPackages.length > 0
        ) {
            authorizedPackages = sortAuthorizedPackages(
                electricityPlanSelection.value.authorizedPackages,
            );
        }
        this.setState({
            authorizedElectricityPackages: authorizedPackages,
            electricityPlanSelection,
        });
    }
    handleElectricityProductSelection(opt: SelectOption<TProduct>) {
        const { onProductSelection } = this.props;
        const nextState: Partial<IState> = {};
        const isCyclical: boolean = !!opt && isCyclicalProduct(opt.value);
        if (isCyclical && !!opt) {
            nextState.electricityPlanSelection = null;
            nextState.electricityFrequencySelection = null;
        }
        nextState.electricityProductSelection = opt;
        nextState.shouldShowGauge = false;
        nextState.electricityOptions = filterOptions(
            _.get(opt, 'value.additionalRates', []),
        );
        nextState.electricityOptionSelection = [];
        nextState.initialElectricityRateOptions = _.get(
            opt,
            'value.additionalRates',
            [],
        );
        this.setState({ ...nextState } as IState);
        const product = _.get(opt, 'value', null);
        onProductSelection(EEnergyTypes.ELECTRICTY, product);
    }

    handleGasFrequencySelection(opt: SelectOption<EInstallmentFrequencies>) {
        this.setState({ gasFrequencySelection: opt });
    }
    handleGasPlanSelection(gasPlanSelection: SelectOption<TPackage>) {
        let authorizedPackages: TAuthorizedPackage[] = [];
        if (
            !!gasPlanSelection &&
            !!gasPlanSelection.value.authorizedPackages &&
            gasPlanSelection.value.authorizedPackages.length > 0
        ) {
            authorizedPackages = sortAuthorizedPackages(
                gasPlanSelection.value.authorizedPackages,
            );
        }
        this.setState({
            authorizedGasPackages: authorizedPackages,
            gasPlanSelection,
        });
    }
    handleGasProductSelection(opt: SelectOption<TProduct>) {
        const { onProductSelection } = this.props;
        const nextState: Partial<IState> = {};
        const isCyclical: boolean = !!opt && isCyclicalProduct(opt.value);
        if (isCyclical && !!opt) {
            nextState.gasPlanSelection = null;
            nextState.gasFrequencySelection = null;
        }
        nextState.gasProductSelection = opt;
        nextState.shouldShowGauge = false;
        nextState.gasOptions = filterOptions(
            _.get(opt, 'value.additionalRates', []),
        );
        nextState.gasOptionSelection = [];
        nextState.initialGasRateOptions = _.get(
            opt,
            'value.additionalRates',
            [],
        );
        this.setState({ ...nextState } as IState);
        const product = _.get(opt, 'value', null);
        onProductSelection(EEnergyTypes.GAS, product);
    }
    handleOptionSelection(electricity: boolean[], gas: boolean[]) {
        this.setState({
            electricityOptionSelection: electricity,
            gasOptionSelection: gas,
        });
    }
    handleSeasonalOffersModalToggle() {
        this.setState({ shouldShowGauge: true });
    }
    handleValidationClick() {
        const { electricityEnabled, gasEnabled, onValidate } = this.props;
        const {
            authorizedElectricityPackageSelection,
            authorizedGasPackageSelection,
            electricityFrequencySelection,
            electricityOptions,
            electricityOptionSelection,
            electricityPlanSelection,
            electricityProductSelection,
            gasFrequencySelection,
            gasOptions,
            gasOptionSelection,
            gasPlanSelection,
            gasProductSelection,
            initialGasRateOptions,
            initialElectricityRateOptions,
        } = this.state;
        let electricityProductWithOptions: TProduct | null = null;
        let gasProductWithOptions: TProduct | null = null;
        const selectedElectricityOptions: TAdditionalRate[] = _.map(
            electricityOptions,
            (rate: TAdditionalRate, index: number) => ({
                ...rate,
                active: electricityOptionSelection[index],
            }),
        );
        const selectedGasOptions: TAdditionalRate[] = _.map(
            gasOptions,
            (rate: TAdditionalRate, index: number) => ({
                ...rate,
                active: gasOptionSelection[index],
            }),
        );
        let selection: any = {};
        if (electricityEnabled && !!electricityProductSelection) {
            electricityProductWithOptions = electricityProductSelection.value;
            electricityProductWithOptions.additionalRates = _.map(
                !isEmpty(initialElectricityRateOptions)
                    ? initialElectricityRateOptions
                    : electricityProductWithOptions.additionalRates,
                (rate: TAdditionalRate) => {
                    const match = _.find(selectedElectricityOptions, rate);
                    if (!!match && match.active) rate.active = true;
                    return rate;
                },
            ).filter((rate: TAdditionalRate) => {
                const isFee = rate.type === EAdditionalRateTypes.FEE;

                if (!!electricityFrequencySelection && isFee) {
                    return matchFrequency(
                        rate,
                        electricityFrequencySelection.value,
                    );
                } else if (isFee) {
                    return false;
                }
                return true;
            });

            if (isCyclicalProduct(electricityProductWithOptions)) {
                selection = {
                    ...selection,
                    electricityProduct: electricityProductWithOptions,
                };
            } else if (
                !!electricityFrequencySelection &&
                !!electricityPlanSelection
            ) {
                selection = {
                    ...selection,
                    electricityFrequency: electricityFrequencySelection.value,
                    electricityPackage: electricityPlanSelection.value,
                    electricityProduct: electricityProductWithOptions,
                    electricitySeasonalPackages: authorizedElectricityPackageSelection,
                };
            } else {
                console.error('Unexpected product type');
                return;
            }
        }
        if (gasEnabled && !!gasProductSelection) {
            gasProductWithOptions = gasProductSelection.value;
            gasProductWithOptions.additionalRates = _.map(
                !isEmpty(initialGasRateOptions)
                    ? initialGasRateOptions
                    : gasProductWithOptions.additionalRates,
                (rate: TAdditionalRate) => {
                    const match = _.find(selectedGasOptions, rate);
                    if (!!match && match.active) rate.active = true;
                    return rate;
                },
            ).filter((rate: TAdditionalRate) => {
                const isFee = rate.type === EAdditionalRateTypes.FEE;
                if (!!gasFrequencySelection && isFee) {
                    return matchFrequency(rate, gasFrequencySelection.value);
                } else if (isFee) {
                    return false;
                }
                return true;
            });
            if (isCyclicalProduct(gasProductWithOptions)) {
                selection = {
                    ...selection,
                    gasProduct: gasProductWithOptions,
                };
            } else if (!!gasFrequencySelection && !!gasPlanSelection) {
                selection = {
                    ...selection,
                    gasFrequency: gasFrequencySelection.value,
                    gasPackage: gasPlanSelection.value,
                    gasProduct: gasProductWithOptions,
                    gasSeasonalPackages: authorizedGasPackageSelection,
                };
            } else {
                console.error('Unexpected product type');
                return;
            }
        }
        onValidate({
            ...selection,
        });
    }
    refresh(
        contracts: TContract[] | null,
        packages: TPackage[] | null,
        products: TProduct[] | null,
        frequencies: TPrepaymentFrequencies[] | null,
    ) {
        let nextState: any = {};
        if (!contracts || !packages || !products) {
            nextState = {
                electricityFrequencySelection: null,
                electricityPlanSelection: null,
                electricityProductSelection: null,
                gasFrequencySelection: null,
                gasPlanSelection: null,
                gasProductSelection: null,
            };
        }

        const mappedFrequencies = mapPackagePrepaymentFrequencies(frequencies);
        const electricityFrequencies: Array<SelectOption<
            EInstallmentFrequencies
        >> = [...mappedFrequencies];
        const gasFrequencies: Array<SelectOption<EInstallmentFrequencies>> = [
            ...mappedFrequencies,
        ];
        const electricityPlans: Array<SelectOption<TPackage>> = [];
        const gasPlans: Array<SelectOption<TPackage>> = [];
        _.forEach(packages, (pkg: TPackage, i: number) => {
            const energy = _.get(pkg, 'energy', '');
            const label = _.get(pkg, 'id');
            if (!label) return false;
            const opt: SelectOption<TPackage> = {
                id: `${i}`,
                label: `${label}${pkg.recommended ? ' (Recommandé)' : ''}`,
                value: pkg,
            };
            if (energy === EEnergyTypes.ELECTRICTY) {
                electricityPlans.push(opt);
            } else if (energy === EEnergyTypes.GAS) {
                gasPlans.push(opt);
            }
        });
        const electricityProducts: Array<SelectOption<TProduct>> = [];
        const gasProducts: Array<SelectOption<TProduct>> = [];
        _.forEach(products, (product: TProduct, i: number) => {
            const energy = _.get(product, 'energy', '');
            const label = _.get(product, 'label');
            if (!label) return false;
            const opt: SelectOption<TProduct> = {
                id: `${i}`,
                label,
                value: product,
            };
            if (energy === (EEnergyTypes.ELECTRICTY as string)) {
                electricityProducts.push(opt);
            } else if (energy === EEnergyTypes.GAS) {
                gasProducts.push(opt);
            }
        });
        const electricityOptions: TAdditionalRate[] = [];
        const electricityOptionSelection: TAdditionalRate[] = [];
        const gasOptions: TAdditionalRate[] = [];
        const gasOptionSelection: TAdditionalRate[] = [];
        nextState = {
            ...nextState,
            authorizedElectricityPackages: [],
            authorizedElectricityPackageSelection: null,
            authorizedGasPackages: [],
            authorizedGasPackageSelection: null,
            electricityFrequencies,
            electricityOptions,
            electricityOptionSelection,
            electricityPlans,
            electricityProducts,
            gasFrequencies,
            gasOptions,
            gasOptionSelection,
            gasPlans,
            gasProducts,
            gaugeTarget: null,
            shouldShowGauge: false,
            electricityFrequencySelection: null,
            electricityProductSelection: null,
            electricityPlanSelection: null,
            gasProductSelection: null,
            gasPlanSelection: null,
            gasFrequencySelection: null,
        };
        this.setState({ ...nextState });
    }
    shouldAllowSeasonalSelection(): boolean {
        const { electricityEnabled, gasEnabled } = this.props;
        const {
            electricityPlanSelection,
            electricityProductSelection,
            gasPlanSelection,
            gasProductSelection,
        } = this.state;
        let should = true;
        const hasCyclicalElectricity: boolean = isCyclicalProduct(
            _.get(electricityProductSelection, 'value', null),
        );
        const hasCyclicalGas: boolean = isCyclicalProduct(
            _.get(gasProductSelection, 'value', null),
        );
        if (hasCyclicalElectricity && hasCyclicalGas) {
            should = false;
        } else if (hasCyclicalElectricity || hasCyclicalGas) {
            if (hasCyclicalElectricity && gasEnabled) {
                should =
                    !!electricityProductSelection &&
                    !!gasProductSelection &&
                    !!gasPlanSelection;
            } else if (hasCyclicalGas && electricityEnabled) {
                should =
                    !!gasProductSelection &&
                    !!electricityProductSelection &&
                    !!electricityPlanSelection;
            } else if (electricityEnabled && !gasEnabled) {
                should =
                    !!electricityProductSelection && !!electricityPlanSelection;
            } else if (!electricityEnabled && gasEnabled) {
                should = !!gasProductSelection && !!gasPlanSelection;
            }
        } else {
            if (electricityEnabled) {
                should =
                    should &&
                    !!electricityProductSelection &&
                    !!electricityPlanSelection;
            }
            if (gasEnabled) {
                should = should && !!gasProductSelection && !!gasPlanSelection;
            }
        }
        return should;
    }
    validateSeasonalOffersModal(
        electricity: TAuthorizedPackage | null,
        gas: TAuthorizedPackage | null,
    ) {
        this.setState({
            authorizedElectricityPackageSelection: electricity,
            authorizedGasPackageSelection: gas,
            shouldShowGauge: false,
        });
    }

    controlChangeAfterSave = (initialParams: Data) => {
        const {
            electricityProductSelection,
            gasProductSelection,
            electricityPlanSelection,
            gasPlanSelection,
            electricityFrequencySelection,
            gasFrequencySelection,
            electricityOptionSelection,
            gasOptionSelection,
            electricityOptions,
            gasOptions,
        } = this.state;

        const { electricityEnabled, gasEnabled } = this.props;

        let checkElec = true;
        let checkGas = true;

        const checkMainElec = (checked: boolean) => checkElec && checked;
        const checkMainGas = (checked: boolean) => checkGas && checked;

        const checkProductSelection = (
            initial: TProduct | null,
            current: SelectOption<TProduct> | null,
        ) => {
            const res =
                initial !== null &&
                current !== null &&
                initial.productCode === current.value.productCode;

            return res === null ? false : (res as boolean);
        };

        const checkPlanSelection = (
            initial: TPackage | null,
            current: SelectOption<TPackage> | null,
        ) => {
            const res =
                initial !== null &&
                current !== null &&
                initial.id === current.value.id;

            return res === null ? false : (res as boolean);
        };

        const checkFrequencySelection = (
            initial: EInstallmentFrequencies | null,
            current: SelectOption<EInstallmentFrequencies> | null,
        ) => {
            const res =
                initial !== null &&
                current !== null &&
                initial === current.value;

            return res === null ? false : (res as boolean);
        };

        if (electricityEnabled) {
            const checkElectricityProductSelection = checkProductSelection(
                initialParams.electricityProductSelection,
                electricityProductSelection,
            );

            const checkElectricityPlanSelection = checkPlanSelection(
                initialParams.electricityPlanSelection,
                electricityPlanSelection,
            );

            const checkElectricityFrequency = checkFrequencySelection(
                initialParams.electricityFrequencySelection,
                electricityFrequencySelection,
            );

            const initialElectricityOptionSelection: boolean[] = _.map(
                electricityOptions,
                (rate: TAdditionalRate) => {
                    return !!_.find(
                        initialParams.electricitySelectedOptions,
                        rate,
                    );
                },
            );

            const checkElectricityOptionSelection = _.isEqual(
                electricityOptionSelection,
                initialElectricityOptionSelection,
            );

            checkElec = checkMainElec(checkElectricityProductSelection);
            checkElec = checkMainElec(checkElectricityPlanSelection);
            checkElec = checkMainElec(checkElectricityFrequency);
            checkElec = checkMainElec(checkElectricityOptionSelection);
        }

        if (gasEnabled) {
            const checkGasProductSelection = checkProductSelection(
                initialParams.gasProductSelection,
                gasProductSelection,
            );

            const checkGasPlanSelection = checkPlanSelection(
                initialParams.gasPlanSelection,
                gasPlanSelection,
            );

            const checkGasFrequency = checkFrequencySelection(
                initialParams.gasFrequencySelection,
                gasFrequencySelection,
            );

            const initialGasOptionSelection: boolean[] = _.map(
                gasOptions,
                (rate: TAdditionalRate) => {
                    return !!_.find(initialParams.gasSelectedOptions, rate);
                },
            );

            const checkGasOptionSelection = _.isEqual(
                gasOptionSelection,
                initialGasOptionSelection,
            );

            checkGas = checkMainGas(checkGasProductSelection);
            checkGas = checkMainGas(checkGasPlanSelection);
            checkGas = checkMainGas(checkGasFrequency);
            checkGas = checkMainGas(checkGasOptionSelection);
        }

        return checkGas && checkElec;
    };

    handleConsumptionModal = () => {
        const { showConsumptionModal } = this.state;

        this.setState({ showConsumptionModal: !showConsumptionModal });
    };

    render() {
        const {
            electricityEnabled,
            initialParams,
            gasEnabled,
            handleSetGaugeProps,
            contracts,
            handleEstimatesPackagesByClient,
        } = this.props;
        const {
            authorizedElectricityPackages,
            authorizedElectricityPackageSelection,
            authorizedGasPackages,
            authorizedGasPackageSelection,
            electricityFrequencies,
            electricityFrequencySelection,
            electricityOptions,
            electricityOptionSelection,
            electricityPlans,
            electricityPlanSelection,
            electricityProducts,
            electricityProductSelection,
            gasFrequencies,
            gasFrequencySelection,
            gasOptions,
            gasOptionSelection,
            gasPlans,
            gasPlanSelection,
            gasProducts,
            gasProductSelection,
            shouldShowGauge,
            showConsumptionModal,
        } = this.state;
        const electricityProductText = tch(
            `${Wording.product} ${Wording.electricity}`,
        );
        const electricityPlanText = tch(
            `${Wording.plan} ${Wording.electricity}`,
        );
        const gasProductText = tch(`${Wording.product} ${Wording.gas}`);
        const gasPlanText = tch(`${Wording.plan} ${Wording.gas}`);
        const frequencyText = tch(Wording.frequency);
        const hasCyclicalElectricityProduct =
            !!electricityProductSelection &&
            isCyclicalProduct(electricityProductSelection.value);
        const hasCyclicalGasProduct =
            !!gasProductSelection &&
            isCyclicalProduct(gasProductSelection.value);
        const allowSeasonalSelection: boolean = this.shouldAllowSeasonalSelection();
        const electricityPlanDefaultLabel: string = hasCyclicalElectricityProduct
            ? Wording.cyclicalPlaceholderText
            : electricityEnabled && !electricityPlanSelection
            ? _.get(initialParams, 'previousElectricityPlanSelection.id')
            : undefined;
        const gasPlanDefaultLabel: string = hasCyclicalGasProduct
            ? Wording.cyclicalPlaceholderText
            : gasEnabled && !gasPlanSelection
            ? _.get(initialParams, 'previousGasPlanSelection.id')
            : undefined;

            const userGroup = localStorage.getItem('userGroup');
            const isAdmin = userGroup === 'ADMIN';

        return (
            <section className={baseClassName}>
                <h2>{Wording.title}</h2>
                <p>{Wording.heading}</p>
                <div className={bcn([baseClassName, selectGridClassName])}>
                    {electricityEnabled && (
                        <div
                            className={bcn([
                                baseClassName,
                                selectGridClassName,
                                'half',
                            ])}
                        >
                            <CustomSelect
                                disabled={
                                    !electricityEnabled ||
                                    electricityProducts.length === 0
                                }
                                handleChangeSelect={
                                    this.handleElectricityProductSelection
                                }
                                options={electricityProducts}
                                text={electricityProductText}
                                value={electricityProductSelection}
                            />
                            <div
                                className={classnames({
                                    'disabled-section': hasCyclicalElectricityProduct,
                                })}
                            >
                                <CustomSelect
                                    disabled={
                                        !electricityEnabled ||
                                        hasCyclicalElectricityProduct ||
                                        electricityPlans.length === 0 ||
                                        !electricityProductSelection
                                    }
                                    handleChangeSelect={
                                        this.handleElectricityPlanSelection
                                    }
                                    options={electricityPlans}
                                    placeholder={electricityPlanDefaultLabel}
                                    text={electricityPlanText}
                                    value={electricityPlanSelection}
                                />
                                <CustomSelect
                                    disabled={
                                        !electricityEnabled ||
                                        hasCyclicalElectricityProduct ||
                                        electricityFrequencies.length === 0 ||
                                        !electricityProductSelection
                                    }
                                    handleChangeSelect={
                                        this.handleElectricityFrequencySelection
                                    }
                                    options={prepareFrequenciesToDisplay(
                                        electricityFrequencies,
                                    )}
                                    text={frequencyText}
                                    value={electricityFrequencySelection}
                                    placeholder={
                                        hasCyclicalElectricityProduct
                                            ? Wording.cyclicalPlaceholderText
                                            : ''
                                    }
                                />
                                <ToggleIndicator
                                    text={Wording.seasonalActivation}
                                    textAlignment={'right'}
                                    toggled={
                                        !!authorizedElectricityPackageSelection
                                    }
                                />
                            </div>
                        </div>
                    )}
                    {gasEnabled && (
                        <div
                            className={bcn([
                                baseClassName,
                                selectGridClassName,
                                'half',
                            ])}
                        >
                            <CustomSelect
                                disabled={
                                    !gasEnabled || gasProducts.length === 0
                                }
                                handleChangeSelect={
                                    this.handleGasProductSelection
                                }
                                options={gasProducts}
                                text={gasProductText}
                                value={gasProductSelection}
                            />
                            <div
                                className={classnames({
                                    'disabled-section': hasCyclicalGasProduct,
                                })}
                            >
                                <CustomSelect
                                    disabled={
                                        !gasEnabled ||
                                        hasCyclicalGasProduct ||
                                        gasPlans.length === 0 ||
                                        !gasProductSelection
                                    }
                                    handleChangeSelect={
                                        this.handleGasPlanSelection
                                    }
                                    options={gasPlans}
                                    placeholder={gasPlanDefaultLabel}
                                    text={gasPlanText}
                                    value={gasPlanSelection}
                                />
                                <CustomSelect
                                    disabled={
                                        !gasEnabled ||
                                        hasCyclicalGasProduct ||
                                        gasFrequencies.length === 0 ||
                                        !gasProductSelection
                                    }
                                    handleChangeSelect={
                                        this.handleGasFrequencySelection
                                    }
                                    options={prepareFrequenciesToDisplay(
                                        gasFrequencies,
                                    )}
                                    text={frequencyText}
                                    value={gasFrequencySelection}
                                    placeholder={
                                        hasCyclicalGasProduct
                                            ? Wording.cyclicalPlaceholderText
                                            : ''
                                    }
                                />
                                <ToggleIndicator
                                    text={Wording.seasonalActivation}
                                    textAlignment={'left'}
                                    toggled={!!authorizedGasPackageSelection}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {allowSeasonalSelection && (
                    <div className={bcn([baseClassName, 'package-manage'])}>
                        <CustomButton
                            color={'gray'}
                            onClick={this.handleSeasonalOffersModalToggle}
                            title={Wording.adjust}
                        />
                       {/* {isAdmin && (<CustomButton
                            color={'gray'}
                            onClick={this.handleConsumptionModal}
                            title={Wording.consumption}
                        />)}*/}
                        
                    </div>
                )}
                <CustomOverlay centered={true} show={shouldShowGauge}>
                    <SeasonalOffersModal
                        authorizedElectricityPackages={
                            authorizedElectricityPackages
                        }
                        authorizedGasPackages={authorizedGasPackages}
                        electricityEnabled={electricityEnabled}
                        dismiss={this.handleSeasonalOfferModalDismiss}
                        gasEnabled={gasEnabled}
                        initialElectricitySelection={
                            authorizedElectricityPackageSelection
                        }
                        initialGasSelection={authorizedGasPackageSelection}
                        onValidate={this.validateSeasonalOffersModal}
                        handleSetGaugeProps={handleSetGaugeProps}
                    />
                </CustomOverlay>
                <Options
                    electricityOptions={electricityOptions}
                    electricityOptionSelection={electricityOptionSelection}
                    gasOptions={gasOptions}
                    gasOptionSelection={gasOptionSelection}
                    onSelection={this.handleOptionSelection}
                />

                {!!initialParams &&
                    !this.controlChangeAfterSave(initialParams) && (
                        <p className="error-message">
                            {Wording.errorMessageAfterChangeInitailParams}
                        </p>
                    )}
                <CustomButton
                    color={'orange'}
                    heavy={true}
                    onClick={this.handleValidationClick}
                    title={Wording.validate}
                />
                <ClientConsumption
                    gasEnabled={gasEnabled}
                    electricityEnabled={electricityEnabled}
                    open={showConsumptionModal}
                    contracts={contracts}
                    handleModalClick={this.handleConsumptionModal}
                    handleEstimatesPackagesByClient={
                        handleEstimatesPackagesByClient
                    }
                />
            </section>
        );
    }
}

export default OfferSection;
