import React, { useState } from 'react';

import { InputEvent } from 'Types';
import {
    CustomTable,
    CustomTransfer,
    CustomProgressbar,
    StackedBar,
    Pagination,
} from '../../components';
import { InputItem } from '../../components/CustomTransfer/CustomTransfer';
import {
    getFormattedDate,
    displayTimeframe,
    getEnergyConsumption,
    getEnergyPercentage,
    getConsumptionDownloadData,
    sortMeters,
} from '../../utils/helpers';
import resources from '../../utils/resources';
import { TMeterRead, TContract } from 'Models';
import { ETimeframe, EReadingFrequency } from '../../utils/enums';

import WordingConstant from '../../utils/wording.json';
import SelfWithdrawalModal from './SelfWithdrawalModal';

const Wording = WordingConstant.ConsumptionPage;
const HeaderWording = WordingConstant.TableHeader.consumption;

const columns = [
    {
        Header: HeaderWording.date,
        accessor: 'meterReadDate',
        Cell: (props: any) => <span>{getFormattedDate(props.value)}</span>,
        width: 100,
    },
    {
        Header: HeaderWording.context,
        accessor: 'consumptionContext',
        width: 230,
    },
    {
        Header: HeaderWording.startDate,
        accessor: 'startDate',
        Cell: (props: any) => <span>{getFormattedDate(props.value)}</span>,
        width: 160,
    },
    {
        Header: HeaderWording.endDate,
        accessor: 'endDate',
        Cell: (props: any) => <span>{getFormattedDate(props.value)}</span>,
        width: 160,
    },
    {
        Header: HeaderWording.quantity,
        accessor: 'quantity',
        Cell: (props: any) => <span>{props.value}</span>,
        width: 180,
    },
    {
        Header: HeaderWording.timeframe,
        accessor: 'timeframe',
        Cell: (props: any) => <span>{props.value}</span>,
        width: 170,
    },
];

export type Props = {
    meters: TMeterRead[];
    inputs: InputItem[];
    contract: TContract;
    errorMeterConfig?: string;
    errorMeterReading?: string;
    handleInputError(): boolean;
    handleInputChange(e: InputEvent, index: number): void;
    handleShowModal(section: string): void;
};

type MeterConsumption = {
    index: number;
    consumption: number;
    timeframe: ETimeframe;
};

const renderSubComponent = (meters: MeterConsumption[]) => {
    return (
        <div className="consumption-container__subtable">
            {meters.map((m: MeterConsumption, index: number) => (
                <div
                    key={index}
                    className="consumption-container__subtable__row"
                >
                    <span className="consumption-container__subtable__row__date" />
                    <span className="consumption-container__subtable__row__context" />
                    <span className="consumption-container__subtable__row__startDate" />
                    <span className="consumption-container__subtable__row__startDate" />
                    <span className="consumption-container__subtable__row__consumption">
                        {m.consumption}
                    </span>
                    <span className="consumption-container__subtable__row__timeframe">
                        {displayTimeframe(m.timeframe)}
                    </span>
                </div>
            ))}
        </div>
    );
};

/**
 * Function to render last month consumption in percentage
 * compared to current month package quantity
 * Formula : consumption / package
 * The last month is the month which appears as the latest endDate
 * and is always displayed at the last bar ====> month = 11
 *
 * This graph is displayed only if readingFrequency = MONTHLY
 * @param contract The current contract
 * @param consumptions The consumption list within 12 months
 * @param packages The package quantity list within 12 months
 */
const renderProgressBar = (
    contract: TContract,
    consumptions: number[],
    packages: number[],
) => {
    if (contract.deliveryPoint.readingFrequency !== EReadingFrequency.MONTHLY) {
        return null;
    }
    const progress = getEnergyPercentage(consumptions, packages, 11);
    const energyPath =
        contract.energy === 'EL'
            ? resources['ic-elec-on']
            : resources['ic-gas-on'];
    return (
        <CustomProgressbar
            steps={100}
            progress={progress}
            pathImg={energyPath}
        />
    );
};

/**
 * FC to render the plotted graph
 * packages: we calculate the data list according to the chosen packages
 * consumptions: the calculation is based on startDate and endDate in meterReads
 * 1st case: only 1 package in contract ===> 12 months share the same quantity
 * 2nd case: 2 packages in contract ===> winter 5 months (November-March) and summer 7 months (April-October)
 * @param contract The current contract
 * @param meters List of meter response
 * @param showProgressBar Display the last month consumption in percentage
 */
type GraphProps = {
    contract: TContract;
    meters: TMeterRead[];
    showProgressBar: boolean;
};

export const Graph = React.memo(
    ({ contract, meters, showProgressBar }: GraphProps) => {
        const nextMeters = sortMeters(meters);

        const energyConsumption = getEnergyConsumption(
            nextMeters,
            contract.chosenPackages,
        );
        const consumptions = energyConsumption.consumptions.map(x =>
            Math.round(x),
        );

        const highs = energyConsumption.highs.map(x => Math.round(x));
        const lows = energyConsumption.lows.map(x => Math.round(x));
        const totalhours = energyConsumption.totalhours.map(x => Math.round(x));
        const labels = energyConsumption.labels;
        const packages = energyConsumption.packages;

        if (nextMeters.length === 0) {
            return null;
        }

        return (
            <>
                <StackedBar
                    packages={packages}
                    consumptions={consumptions}
                    highs={highs}
                    lows={lows}
                    totalhours={totalhours}
                    labels={labels}
                />
                {showProgressBar &&
                    renderProgressBar(contract, consumptions, packages)}
            </>
        );
    },
);

const ConsumptionContainer: React.FC<Props> = ({
    meters,
    inputs,
    contract,
    errorMeterConfig,
    errorMeterReading,
    handleInputError,
    handleInputChange,
    handleShowModal,
}) => {
    const [isOpen, setOpen] = useState(false);
    const handleModal = () => setOpen(v => !v);

    const filteredMeters = meters.filter(
        (meter) => meter.consumptionContext === 'PERIODIC_METERING'
    );

    const description = `${Wording.autoTransfer.description1} ${
        inputs[0].maxLength
    }  ${
        Wording.autoTransfer[
            `description2-${contract.energy}` as
                | 'description2-NG'
                | 'description2-EL'
        ]
    }`;

    const downloadData = getConsumptionDownloadData(meters);
    const downloadFileName = Wording.history.downloadFilename;
    const downloadHeaders = Wording.history.downloadHeaders;
    const showPagination = meters.length > 12;

    return (
        <div className="consumption-container">
            <Graph contract={contract} meters={filteredMeters} showProgressBar={true} />

            <CustomTransfer
                title={Wording.autoTransfer.title}
                modalTitle={
                    Wording.SelfWithdrawalModal.titles[
                        contract.energy as 'EL' | 'NG'
                    ]
                }
                description={description}
                buttonTitle={Wording.autoTransfer.buttonTitle}
                inputs={inputs}
                handleInputChange={handleInputChange}
                handleButtonClick={() => {
                    if (handleInputError()) {
                        handleShowModal('');
                    }
                }}
                error={errorMeterConfig}
                handleModal={handleModal}
            />
            <CustomTable
                data={meters}
                columns={columns}
                defaultPageSize={12}
                pageSize={meters.length}
                sortable={true}
                showPagination={showPagination}
                showPaginationBottom={showPagination}
                title={Wording.history.title}
                downloadButtonTitle={
                    meters.length > 0 ? Wording.history.buttonTitle : undefined
                }
                downloadHeaders={downloadHeaders}
                downloadData={downloadData}
                downloadFilename={downloadFileName}
                noDataText={Wording.noDataText}
                error={errorMeterReading}
                className={meters.length > 0 ? '-striped' : ''}
                PaginationComponent={showPagination ? Pagination : undefined}
                SubComponent={(row: any) => {
                    return renderSubComponent(meters[row.index].meterRead);
                }}
            />

            <SelfWithdrawalModal
                open={isOpen}
                handleModal={handleModal}
                energy={contract.energy}
            />
        </div>
    );
};

export default ConsumptionContainer;
